export default {
  Checkout (state) {
    state.personalInfo = {
      customer_first_name: '',
      customer_last_name: '',
      customer_phone_number: ''
    };
    state.pickup = false,
    state.payment_method = '',
    state.billing_address = {
      address_name: '',
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      country: '',
      city: '',
      region: '',
      details: ''
    };
    state.shipping_address= {
      address_name: '',
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      country: '',
      city: '',
      region: '',
      details: ''
    }
  },
  Pickup(state, val) {
    state.pickup = val
  },  
  Delivery(state, val) {
    state.delivery = val
  },
  PersonalInfo(state, val) {
    state.personalInfo = val
  },
  PaymentMethod(state, val) {
    state.payment_method = val
  },
  ShippingAddress(state, val) {
    state.shipping_address = val
  },
  BillingAddress(state, val) {
    state.billing_address = val
  }
}
