import { createStore } from 'vuex'
import LocaleHelper from '@/helpers/LocaleHelper'
import normalCheckout from './modules/checkout/normalCheckout'
import geustCheckout from './modules/checkout/geustCheckout'

import globalGetters from './getters'
import globalMutations from './mutations'
import globalActions from './actions'
import CurrencyHelper from '@/helpers/CurrencyHelper'

export default createStore({
  state: {
    mobile: false,
    LgXlGap:false ,
    loader: false,
    screen: window.innerWidth,
    currentLocale : LocaleHelper.getCurrentLocale() ?? '',
    currentCurrency : CurrencyHelper.getCurrentCurrency() ?? {},
    storeName: '',
    addressCount:0,
    searchPopup:false
  },
  getters: globalGetters,
  mutations:globalMutations,
  actions: globalActions,
  modules: {
    checkout : normalCheckout,
    geustCheckout: geustCheckout
  }
})
