<template>
        <div class="row bg">
            <div class="col-lg-11 px-lg-0 col-11  mx-auto">
                <div class="row">
                    <div class="col-lg-6 col-12">
                        <CardsSlider slidesPerView="2"/>
                    </div>
                    <div class="col-lg-6 col-12 h-100 d-flex flex-column my-auto text-mobile-style">
                        <h2 class="semibold mb-3 primary-font">{{ this.header.title }}</h2>
                        <p class="f-16 medium w-100 mb-4">{{ this.header.paragraph }} </p> 
                        <SearchBar class="w-90" position="relative" v-if="this.header.enable_searchbar && !mobile"/>
                    </div>
                    
                </div>
            </div>
        </div>
</template>
<script>
import  CardsSlider from '@/components/UI/ImageCardSlider.vue'
import  SearchBar from '@/components/UI/SearchBar.vue'
export default {
    inject:['header'],
    components: {
        CardsSlider,
        SearchBar
    },
    computed:{
        mobile(){
          return  this.$store.getters.getMobile;
        }
    }
};
</script>
<style scoped>
.bg {
    background-image: url('../../../assets/img/default/placeholder.svg');
    background-size: cover;
    background-position-y: top;
}
.w-90{
    width: 90%;
}
@media (max-width: 992px) {
    .w-90{
    width: 100%;
}
.text-mobile-style{
    text-align: center;
    margin-top: 10px!important;
}
.text-mobile-style p{
    line-height: 1.8;
}
.text-mobile-style h2{
color: var(--primary-color);
}
}
</style>
 
 