import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'

import './assets/css/settings.css'
import './assets/css/static.css'
import 'animate.css';
import i18n from './i18n'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueImageZoomer from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';
import 'font-awesome/css/font-awesome.min.css'
import "@vueform/slider/themes/default.css"
import JQuery from 'jquery'
import "jquery-ui-slider/jquery-ui.min.js";
import "jquery-ui-slider/jquery-ui.min.css";
window.$ = JQuery

createApp(App).use(VueImageZoomer).use(VueSweetalert2).use(i18n).use(store).use(router).mount('#app')
 