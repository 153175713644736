<template>
    <section class="ads-banner">
        <div class="col-lg-11 px-lg-0 col-12  mx-auto my-5">
            <swiper :loop="true" slidesPerView="1" :autoplay="{
                delay: 2000,
                disableOnInteraction: false,
            }" :navigation="true" :modules="modules" class="mySwiper ads-swiper">
                <swiper-slide v-for="(ad, index) in this.ads.images" :key="index">
                    <!-- <a :href="ad.goToUrl"> -->
                    <a :href="ad.direction_url" v-if="ad.direction_url" target="__blank">
                        <div class="ads-banner-dim" v-bind:style="{ 'background-image': 'url(' + ad.image_url + ')' }">
                        </div>
                    </a>
                    <div v-else class="ads-banner-dim" v-bind:style="{ 'background-image': 'url(' + ad.image_url + ')' }">
                    </div>
                    <!-- </a> -->
                </swiper-slide>

            </swiper>
        </div>
    </section>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css/navigation';

import { Autoplay } from 'swiper/modules';
// import required modules
import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';

export default {
    props: {
        ads: {
            type: Object
        }
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Navigation, Autoplay],
        };
    },
}
</script>
<style scoped>
.ads-banner-dim {
    height: 26vw;
    width: 100%;
    background-position: center;
    background-size: cover;
    border-radius: 23px;
}
</style>