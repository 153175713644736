<template>
    <form class="d-flex text-center t-0 b-0 l-0 r-0 my-auto " :class="this.position"
        @submit.stop.prevent="submitSearchTerm">
        <div class="position-relative w-100 d-flex align-items-center search-container ">
            <input class="form-control primary-border f-14 px-2 px-xl-3" type="search" :placeholder="$t('searchbar-placeholder')"
                aria-label="Search" v-model="searchTerm">
            <button class="btn search-btn secondary-bg position-absolute w-25 white" type="submit">
                {{ $t('search') }}
            </button>
            <button class="btn search-btn secondary-bg position-absolute w-20 white" type="submit">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22.473 22.456">
                    <path id="Outline"
                        d="M2.289,22.192l4.1-4.062a9.935,9.935,0,1,1,1.534,1.534L3.857,23.727a1.1,1.1,0,0,1-1.568-1.534Zm11.823-2.528A7.727,7.727,0,1,0,8.648,17.4,7.727,7.727,0,0,0,14.112,19.664Z"
                        transform="translate(-2.013 -1.559) rotate(-1)" fill="#ffffff" />
                </svg>
            </button>
        </div>
    </form>
</template>
<script>
export default {
    props: {
        position: {
            default: 'relative'
        }
    },
    data() {
        return {
            searchTerm: ''
        }
    },
    methods: {
        submitSearchTerm() {
            //if you want to send any data into server before redirection then you can do it here
            if (this.searchTerm.length > 3)
                this.$router.push({ name: 'search', query: { search_term: this.searchTerm } })

            if (this.$route.query.search_term != '' && this.$route.query.search_term != null && this.$route.query.search_term != undefined)
                this.$router.replace({
                    name: 'search', query: {
                        search_term: this.searchTerm
                    },
                })
        }

    }
}
</script>
<style scoped>
.relative {
    justify-content: start;
    /* position: relative; */
}

.absolute {
    justify-content: center;
    position: absolute;
}

form {
    z-index: 2;
}

.form-control {
    border-radius: 50px;
    height: 45px;
}

.search-btn {
    border-radius: 50px !important;
    border: none;
}

.arabic .search-btn {
    left: 0
}

.english .search-btn {
    right: 0;
}

.white {
    color: white;
    height: 45px;
}

.search-container,
form {
    height: fit-content;
}

.btn:hover {
    color: white !important;
    ;
    background-color: var(--primary-color);
    border: none;
}

button.position-absolute.w-20 {
    display: none !important;
}


button.position-absolute.w-25 {
        display: initial !important;
    }

@media (max-width: 1049.98px) {
    input {
        text-indent: 0px;
    }

    .w-20 {
        width: 20%;
    }

    button.position-absolute.w-25 {
        display: none !important;
    }

    button.position-absolute.w-20 {
        display: initial !important;
    }

}
</style>