import axios from 'axios'
import LocaleHelper from "@/helpers/LocaleHelper";
import AuthHelper from './AuthHelper';
import CurrencyHelper from './CurrencyHelper';
const baseDomain = process.env.VUE_APP_BASE_API_URL
const BASE_URL = `${baseDomain}/api`
const DEBUG = process.env.NODE_ENV === "development";
const LOCAL = LocaleHelper.getCurrentLocale();
const Currency = CurrencyHelper.getCurrentCurrencyId();
const sharedHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-locale' : LOCAL,
  'x-currency':Currency
}

if (AuthHelper.hasToken()) {
    sharedHeaders.Authorization = `Bearer ${AuthHelper.getToken()}`;
}

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: sharedHeaders,
  withCredentials: true, // To enable cookies,
  https: !DEBUG ,
})
axiosInstance.interceptors.request.use(config => {
    if (AuthHelper.hasToken())
        config.headers.Authorization =  `Bearer ${AuthHelper.getToken()}`;
    // if (DEBUG)
    //     console.trace("✉️ ", config);
    return config;
}, error => {
    // if (DEBUG)
    //     console.trace("✉️ ", error);
    return Promise.reject(error);
});

// Send all the shared params with the request
// axiosInstance.interceptors.request.use(config => {
//     const shared = {
//         // currency: getCurrentCurrency(),
//         // city_id: StateHelper.getState(),
//         token: true,
//     };
//     if (config.method === 'get')
//         config.params = {
//             ...config.params,
//             ...shared
//         };
//     else
//         config.data = {
//             ...config.data,
//             ...shared
//         };
//     return config;
// });

// Refreshing the token when response has 403 status code
// axiosInstance.interceptors.response.use(response => response, async error => {
//     const originalRequest = error.response;
//     // console.log(originalRequest);
//     if (error.response.status === 401 && !originalRequest._retry && AuthHelper.hasToken()) {
//         // _retry to prevent looping
//         originalRequest._retry = true;
//         // Use fetch to prevent loops
//         try {
//             let refreshToken = await axios.post('customer/refresh', {
//                 token: true
//             }, {
//                 headers: sharedHeaders,
//                 baseURL: BASE_URL,
//             });
//             AuthHelper.setToken(refreshToken.data.token);
//             axiosInstance.defaults.headers['Authorization'] = `Bearer ${refreshToken.data.token}`;
//             originalRequest.headers['Authorization'] = `Bearer ${refreshToken.data.token}`;
//             // axiosInstance.config.headers.Authorization = `Bearer ${refreshToken.token}`;
//         } catch (refreshException) {
//             AuthHelper.logout();
//             console.log(refreshException);
//             return Promise.reject(error);
//         }
//         return axiosInstance(originalRequest);
//     }
//     return Promise.reject(error);
// });

export default axiosInstance
