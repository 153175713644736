import BaseRepository from "@/repositories/Repository";

export default class ProductRepository extends BaseRepository{

    async get(productId ) {
        return this.http.get(`products/${productId}`);
    }
    async getFeatured(){
        return this.http.get(`featured-products`);
    }
    async search(params) {
        return this.http.get(`products?${params}`);
    }
   
}
