export default {
    getPaymentId (state) {
        return state.payment_method
      },
      getShippingId (state) {
        return state.shipping_address_id
      },
      getBillingId (state) {
        return state.billing_address_id
      },
      getDetails (state) {
        return state
      },
      getPickup (state){
        return state.pickup
      }
}