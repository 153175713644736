<template>
  <div id="home">
    <div class="cms-section" v-for="(block, index) in CMSBlocks" :key="block.name + '-' + index">
      <Header :header="block" v-if="block.name == 'Header'" />
      <Categories :categories="block" v-else-if="block.name == 'Categories'" />
      <ProductSliderWithTitle :title="$t('latest-products')" :products="block" v-else-if="block.name == 'Products'" />
      <AdsBanner :ads="block" v-else-if="block.name == 'Ads'" />
    </div>
  </div>
</template>
<script>
import ProductSliderWithTitle from '../components/blocks/products/ProductsSection.vue'
import Header from '@/components/cms/controllers/Header.vue'
import Categories from '@/components/cms/controllers/Categories.vue'
import AdsBanner from '@/components/blocks/ads/AdsBanner.vue'
import ServiceFactory from "@/services/ServiceFactory"
import metaMixin from '@/mixins/metaMixin'
import navMixin from '@/mixins/navMixin'
const CMS = ServiceFactory.get("CMS")
// @ is an alias to /src
export default {
  mixins: [metaMixin,navMixin],
  data() {
    return {
      CMSBlocks: {},
    }
  },

  async mounted() {
    window.scrollTo(0, 0);
    this.CMSBlocks = await CMS.CMSBlocks();
    this.updateTitle(this.$store.getters.getStoreName, this.$t('home'));
  },
  components: {
    ProductSliderWithTitle,
    Categories,
    Header,
    AdsBanner
  },
  
}
</script>