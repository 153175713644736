<template>
    <div class="w-100 justify-content-center align-items-center mb-3">
        <div class="col-12 px-0">
            <swiper :pagination="{
                clickable: true,
            }"  :zoom="true" :modules="modules" class="mySwiper">
                <swiper-slide v-for="(img, index) in images" :key="index">
                    <div class="swiper-zoom-container">
                        <img :src="img" :alt="`${productName}img`" class="img-style">
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination, Zoom } from 'swiper/modules';
export default {
    props: {
        images: {
            type: Array
        },
        productName: {
            type: String
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
    setup() {
        return {
            modules: [Pagination, Zoom],
        };
    },
};

</script>
<style scoped>
.img-style {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    height: 100%;
    margin:auto
}

.swiper-zoom-container {
    height: 100%;
    width: 100%;
    display: flex;
}

.mySwiper {
    height: 71vh;
}
</style>
  