import CurrencyHelper from "@/helpers/CurrencyHelper"
import LocaleHelper from "@/helpers/LocaleHelper"

export default {
  Mobile (state, isMobile) {
    state.mobile = isMobile
  },
  LgXlGap (state,isGap) {
    state.LgXlGap = isGap
  },
  Loader (state,load) {
    state.loader = load
  },
  Screen (state) {
    state.screen = window.innerWidth
  },  
  Store(state ,name){
    state.storeName = name
  },
  CurrentLocale(state ,locale){
    LocaleHelper.changeLocale(locale)
  },
  CurrentCurrency(state ,currency){
    CurrencyHelper.changeCurrency(currency);
  },
  AddressCount(state ,count){
    state.addressCount = count
  },
  SearchPopup(state , val){
    state.searchPopup = val
  }
}