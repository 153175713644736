import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import ProductView from '../views/Product.vue'
import AuthHelper from '@/helpers/AuthHelper'
function authGaurd (to, from, next) {
  var isAuthenticated = false
  if (AuthHelper.hasToken()) isAuthenticated = true
  else isAuthenticated = false
  if (isAuthenticated) {
    next()
  } else
    next({
      path: '/login',
      query: {
        redirect: to.fullPath
      }
    })
}
function geustGaurd (to, from, next) {
  var isGeust = false
  if (!AuthHelper.hasToken()) isGeust = true
  else isGeust = false
  if (isGeust) {
    next()
  } else {
    next({
      name: 'profile'
    })
  }
}
const routes = [
  {
    path: '/',
    component: HomeView,
    name: 'home'
  },
  {
    path: '/about-us',
    name: 'aboutus',
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/FAQ.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import('../views/TermsAndConditions.vue')
  },
  {
    path: '/return-and-refund-policy',
    name: 'return-and-refund-policy',
    component: () => import('../views/ReturnAndRefund.vue')
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: geustGaurd,
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    beforeEnter: geustGaurd,
    component: () => import('../views/Register.vue')
  },
  {
    path: '/category/:category_id/:slug',
    name: 'category',
    component: () => import('../views/Category.vue')
  },
  {
    path: '/products/:product_id/:slug',
    name: 'product',
    props: true,
    component: ProductView
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/Search.vue')
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('../views/Categories.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/Cart.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/Checkout.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    beforeEnter: authGaurd,
    component: () => import('../views/Profile.vue'),
    children: [
      {
        path: '',
        name: 'profile-default',
        component: () =>
          import('../components/features/profile/PersonalInfo.vue')
      },
      {
        path: 'addresses',
        name: 'addresses',
        children: [
          {
            path: '',
            name: 'index',
            component: () =>
              import('../components/features/addresses/index.vue')
          },
          {
            path: ':address_id',
            name: 'address',
            component: () =>
              import('../components/features/addresses/ViewAddress.vue')
          },
          {
            path: 'create-address',
            name: 'create-address',
            component: () =>
              import('../components/features/addresses/CreateAddress.vue')
          }
        ],
        component: () =>
          import('../components/features/addresses/Addresses.vue')
      },
      {
        path: 'orders',
        name: 'orders',
        component: () => import('../components/features/orders/Orders.vue')
      },
      {
        path: 'order/:order_id',
        name: 'order',
        component: () =>
          import('../components/features/orders/OrderDetails.vue')
      },
    
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
