export default {
  getPayment (state) {
    return state.payment_method
  },
  getPersonalInfo (state) {
    return state.personalInfo
  },
  getShipping (state) {
    return state.shipping_address
  },
  getBilling (state) {
    return state.billing_address
  },
  getPickup (state) {
    return state.pickup
  },
  getDelivery (state) {
    return state.delivery
  },
  getDetails (state) {
    return state
  }
}
