import Customer from '@/models/Customer'
import RepositoryFactory from '@/repositories/RepositoryFactory'
import Order from '@/models/Order'
import Address from '@/models/Address'
import cookies from '@/helpers/CookiesHelper'
import store from '@/store'
import State from '@/models/State'
import Country from '@/models/Country'

export default class CustomerService {
  constructor () {
    this.customer = RepositoryFactory.get('customer')
  }

  async get () {
    store.dispatch('setLoader', true)
    return this.customer
      .get()
      .then(response => {
        let customer = new Customer(response.data.data)
        return Promise.resolve(customer)
      })
      .catch(err => Promise.resolve(null))
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }

  async update (data) {
    store.dispatch('setLoader', true)
    return this.customer
      .update(data)
      .then(response => {
        return Promise.resolve({
          customer: new Customer(response.data.data),
          message: response.data.message
        })
      })
      .catch(err => Promise.reject(err.response.data))
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }

  async getOrders () {
    store.dispatch('setLoader', true)
    return this.customer
      .getOrders()
      .then(response => {
        let data = response.data.data
        let orders = response.data.data
        return Promise.resolve(orders)
      })
      .catch(err => Promise.resolve(null))
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }

  async getAddresses () {
    store.dispatch('setLoader', true)
    return this.customer
      .getAddresses()
      .then(response => {
        let data = response.data.data
        let addresses = data.map(address => new Address(address))
        store.dispatch('setAddressCount', addresses.length)
        return Promise.resolve(addresses)
      })
      .catch(err => Promise.reject(null))
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }

  async createAddress (address) {
    store.dispatch('setLoader', true)
    return this.customer
      .createAddress(address)
      .then(response => {
        return Promise.resolve({
          address: new Address(response.data.data),
          message: response.data.message
        })
      })
      .catch(err => {
        return Promise.resolve({
          address: null,
          message: err.message
        })
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }

  async updateAddress (id, newAddress) {
    store.dispatch('setLoader', true)
    return this.customer
      .updateAddress(id, newAddress)
      .then(response => {
        return Promise.resolve({
          address: new Address(response.data.data),
          message: response.data.message
        })
      })
      .catch(err => {
        return Promise.reject({
          address: null,
          message: err.message
        })
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }

  async deleteAddress (id) {
    store.dispatch('setLoader', true)
    return this.customer
      .deleteAddress(id)
      .then(response => {
        return Promise.resolve({
          success: true,
          address: null,
          message: response.data.message
        })
      })
      .catch(err => {
        return Promise.resolve({
          address: null,
          message: err.message
        })
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }

  async getAddressDetails (id) {
    store.dispatch('setLoader', true)
    return this.customer
      .getAddressDetails(id)
      .then(response => {
        let data = response.data.data
        let address = new Address(data)
        return Promise.resolve(address)
      })
      .catch(err => {
        return Promise.reject(null)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }

  async getCountries () {
    store.dispatch('setLoader', true)
    return this.customer
      .getCountries()
      .then(response => {
        let cs = response.data.data
        let countries = cs.map(country => new Country(country))
        return Promise.resolve(countries)
      })
      .catch(err => {
        return Promise.resolve(err)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }

  async getStates (country_id) {
    store.dispatch('setLoader', true)
    return this.customer
      .getStates(country_id)
      .then(response => {
        let ss = response.data.data
        let states = ss.map(state => new State(state))
        return Promise.resolve(states)
      })
      .catch(err => {
        return Promise.reject(null)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }

  async getOrderDetails (order_id) {
    store.dispatch('setLoader', true)
    return this.customer
    .getOrderDetails(order_id)
    .then(response => {
      var order = new Order(response.data.data)
      return Promise.resolve(order)
    })
    .catch(err => {
      return Promise.resolve(null)
    })
    .finally(() => {
      store.dispatch('setLoader', false)
    })
  
  }
   getOrderInvoice(order_id){
    console.log(order_id)
    store.dispatch('setLoader', true)
    return this.customer
    .getOrderInvoice(order_id)
    .then(response => {
      const url = response.data.data.url;
   return Promise.resolve(url);
    })
    .catch(err => {
      console.log(err)
       Promise.resolve(null)
    })
    .finally(() => {
      store.dispatch('setLoader', false)
    })
  }
}
