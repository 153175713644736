import AuthHelper from '@/helpers/AuthHelper'
import RepositoryFactory from '@/repositories/RepositoryFactory'
import store from '@/store'
export default class AuthService {
  constructor () {
    this.auth = RepositoryFactory.get('auth')
  }
  async login (credentials) {
    store.dispatch('setLoader', true)
    return this.auth
      .login(credentials)
      .then(response => {
        AuthHelper.setToken(response.data.data.token)
        return Promise.resolve(response.data.success)
      })
      .catch(error => {
        return Promise.resolve(error.response.data.message)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }

  async register (credentials) {
    store.dispatch('setLoader', true)
    return this.auth
      .register(credentials)
      .then(response => {
        AuthHelper.setToken(response.data.data.token)
        return Promise.resolve(response.data.success)
      })
      .catch(error => {
        return Promise.resolve(error.response.data.message)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }
  async logout () {
    store.dispatch('setLoader', true)
    return this.auth.logout()
      .then(response => {
        AuthHelper.logout();
        return Promise.resolve(response.data.success)
      })
      .catch(error => {
        Promise.resolve(error.response.data.message)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }
}
