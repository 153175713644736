export default {
  clearCheckout (context) {
    context.commit('Checkout')
  },
  setPersonalInfo (context, payload) {
    context.commit('PersonalInfo', payload)
  },
  setPaymentMethod (context, payload) {
    context.commit('PaymentMethod', payload)
  },
  setShipping (context, payload) {
    context.commit('ShippingAddress', payload)
  },
  setBilling (context, payload) {
    context.commit('BillingAddress', payload)
  },
  setPickup (context, payload) {
    context.commit('Pickup', payload)
  },
  setDelivery (context, payload) {
    context.commit('Delivery', payload)
  }
}
