import BaseRepository from "@/repositories/Repository";

export default class CheckoutRepository extends BaseRepository {

    async addresses() {
        return this.http.get('addresses');
    }
    async paymentMethods() {
        return this.http.get('get-payment-methods');
    }
    async saveOrder(order) {
        return this.http.post('checkout',order);
    }
    async getCountries() {
        return this.http.get('countries');
    }
    async getStates() {
        return this.http.get('country-cities');
    }
    async getSummary() {
        return this.http.get('checkout-summary');
    }
}