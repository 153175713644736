import BaseRepository from '@/repositories/Repository'

export default class CustomerRepository extends BaseRepository {
  async get () {
    return this.http.get('profile')
  }

  async update (data) {
    return this.http.post('/profile/update', data , { headers: {
      "Content-Type": "multipart/form-data"
    }})
  }

  async getOrders () {
    return this.http.get('orders')
  }

  async getOrderDetails (orderId) {
    return this.http.get(`orders/${orderId}`)
  }

  async getAddresses () {
    return this.http.get('addresses/my-addresses')
  }

  async updateAddress (id, newAddress) {
    return this.http.post(`addresses/update/${id}`, newAddress)
  }

  async deleteAddress (id) {
    return this.http.post(`addresses/delete/${id}`)
  }

  async createAddress (address) {
    return this.http.post('addresses/store', address)
  }

  async getAddressDetails(id){
    return this.http.get(`addresses/show/${id}`)
  }
  
  async getCountries(){
    return this.http.get('countries')
  }
  
  async getStates(state='0'){
    return this.http.get(`states?country_id=${state}`)
  }
  async getOrderDetails(order_id) {
    return this.http.get(`orders/${order_id}`)
  }
  async getOrderInvoice(order_id){
    return this.http.get(`orders-invoice/${order_id}`)
  }
}
