<template>
    <div class="ltr">
        <VueDatePicker v-model="date" range :enable-time-picker="require_hour" prevent-min-max-navigation :clearable="false"
            :placeholder="$t('reservation-date')" :format="format" ignore-time-validation no-today auto-apply :min-date="minDate"
            :partial-range="false" v-if="range">
        </VueDatePicker>
        <VueDatePicker v-model="date" :enable-time-picker="require_hour" day-picker prevent-min-max-navigation
            :clearable="false" :placeholder="$t('reservation-date')" :format="format" ignore-time-validation no-today auto-apply
            :min-date="minDate" :partial-range="false" v-else>
        </VueDatePicker>
    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import '@/assets/css/DatePicker.css'
export default {
    emits: ['reservation'],
    props: {
        require_hour: {
            type: Boolean,
            default: false
        },
        range: {
            type: Boolean,
            default: false
        },
    },
    components: {
        VueDatePicker
    },
    data() {
        return {
            date: [],
            reservation: {
                checkin: '',
                checkin_time: '',
                checkout: '',
                checkout_time: ''
            },
            minDate: new Date(),
             format : (date) => {
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();

                return `${day}/${month}/${year}`;
            }
        }
    },
    watch: {
        date(newDate) {
            if (!this.range && newDate) {
                this.dateVal(newDate, 'checkin')
            }
            else {
                if (this.range && newDate[0]) {
                    this.dateVal(newDate[0], 'checkin')
                }
                if (this.range && newDate[1]) {
                    this.dateVal(newDate[1], 'checkout')
                }
            }
            var flag = true;
            if (this.reservation.checkin != '') {
                if (this.require_hour && (this.reservation.checkin_time == '' || (this.rang && this.reservation.checkout_time == ''))) {
                    flag = false
                }
                if (this.rang && this.reservation.checkout == '') {
                    flag = false
                }
            }
            else {
                flag = false
            }
            if (flag)
                this.$emit('reservation', this.reservation)

        },

    },
    methods: {
        dateVal(date, key) {
            if (date != undefined) {
                var h = date.getHours();
                var m = date.getMinutes();
                var day = date.getDate();
                var month = date.getMonth() + 1;
                var year = date.getFullYear();
                if (key == 'checkin') {
                    this.reservation.checkin = `${day}-${month}-${year}`;
                    if (this.require_hour)
                        this.reservation.checkin_time = `${h}:${m}`
                } else if (key == 'checkout' && this.range) {
                    this.reservation.checkout = `${day}-${month}-${year}`;
                    if (this.require_hour)
                        this.reservation.checkout_time = `${h}:${m}`
                }
            }

        }


    }
}
</script>
<style scoped>
.ltr {
    direction: ltr;
}
</style>
 