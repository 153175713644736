
<template>
  <div class="card position-relative shadow">
    <div class="img-top position-relative w-100">
      <div class="card-img">
        <router-link :to="{
          name: 'product', params: {
            slug: product.seo_meta ? product.seo_meta.slug:product.url_key,
            product_id: product.id
          },
        }">
          <div class="img-container mt-2 mx-2" v-bind:style="{ 'background-image': 'url(' + product.base_image + ')' }">
          </div>
        </router-link>
        <div v-if="product.type=='product'" class="primary-bg add-to-cart-btn position-absolute align-items-center b-0 d-flex py-2" @click="addToCart()">
          <i class="fa fa-cart-plus f-24 px-3 white" aria-hidden="true"></i>
          <p class="mb-0 f-14 p-0  white text semibold white">
            {{ $t('add-to-cart') }}
          </p>
        </div>
      </div>
    </div>
    <router-link :to="{
      name: 'product', params: {
        slug: product.seo_meta ? product.seo_meta.slug:product.url_key,
        product_id: product.id
      },
    }">
      <div class="card-body text-center px-2 pb-3 pt-2">

        <p class="card-title mb-1 f-16 semibold">{{ product.name }}</p>
        <p class="card-title mb-0 f-14 primary-font semibold">
          {{ product.formatted_price }}
        </p>
        <div v-if="product.type=='product'"  class="primary-bg  justify-content-center align-items-center mobile-add-to-cart" @click="addToCart()">
          <p class="mb-0 f-16 px-0 py-3  white text semibold white">
            {{ $t('add-to-cart') }}
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>
<script>
import ServiceFactory from '@/services/ServiceFactory';
const cart = ServiceFactory.get("cart");

export default {
  data() {
    return {
      qty: '1',
    }

  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  methods: {
   async addToCart() {
    var here = this;
      if (this.product)
        if (await cart.add(this.product.id, {'quantity':this.qty}))
          this.$swal.fire({
            icon: 'success',
            text: here.$t('added-to-cart'),
            timer: 1000,
            confirmButtonText: here.$t('ok')
          })
  }
},
data() {
  return {
    img: '../../assets/img/static/product-1.png'
  }
}
}
</script>
<style scoped>
.white {
  color: white !important;
}

.card {
  border: none;
}

.mobile-add-to-cart {
  display: none !important;
}

.add-to-cart-btn p {
  text-wrap: nowrap;
}

.add-to-cart-btn p.text {
  opacity: 0;
  transition: all .2s ease-in-out;

}

.f-24 {
  font-size: 24px;
}

.add-to-cart-btn:hover p.text {
  opacity: 1;
  transition: all .2s ease-in-out;
}

.add-to-cart-btn {
  height: min-content;
  overflow: hidden;
  height: 25%;
  width: 25%;
  cursor: pointer;
  left: 0;
  border-radius: 0px 50px 50px 0px;
  transition: all .2s ease-in-out;
}

.add-to-cart-btn:hover {
  cursor: pointer;
  width: 84%;
  transition: all .2s ease-in-out;
}

.card-title {
  overflow: hidden;
  width: 180px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.arabic .card-body {
  text-align: right;
}

.english .card-body {
  text-align: left;
}

.card {
  width: fit-content;
  border-radius: 14px
}

.img-top {
  text-align: center;
  justify-content: center;
  display: flex;
}

.img-container {
  height: 180px;
  width: 180px;

  margin: auto;
  border-radius: 14px;
  background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
}


@media (max-width: 575.98px) {
  .img-container {
    height: 200px;
    width: 200px;
  }

  .card-title {
    overflow: hidden;
    width: 200px;
  }
}

@media (max-width: 369.98px) {
  .card {
    margin: auto;
  }

  .img-container {
    height: 250px;
    width: 250px;
  }

  .card-title {
    overflow: hidden;
    width: 250px;
  }
}

@media (max-width: 767.98px) {}

@media (max-width: 991.98px) {}

@media (max-width: 1199.98px) {
  .mobile-add-to-cart {
    border-radius: 50px;
    width: fit-content;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 45px;
    margin-top: .5rem;
  }

  .add-to-cart-btn {
    display: none !important;
  }

  .card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .mobile-add-to-cart {
    display: flex !important;
  }
}
</style>