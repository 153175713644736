export default {
  //normal checkout
  clearCheckout (context) {
    context.commit('Checkout')
  },
  setPaymentMethod (context, payload) {
    context.commit('PaymentMethod', payload)
  },
  setShippingId (context, payload) {
    context.commit('ShippingId', payload)
  },
  setBillingId (context, payload) {
    context.commit('BillingId', payload)
  },
  setPickup(context ,val){
      context.commit('Pickup', val)
  }
}
