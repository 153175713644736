import BaseRepository from "@/repositories/Repository";

export default class CategoryRepository extends BaseRepository{
    
    async get(categoryId) {
        return this.http.get(`categories/${categoryId}`);
    }

    async list() {
        return this.http.get('categories');
    }
}