<template>
  <nav class="navbar navbar-expand-xl w-100" id="navigation-bar" :class="{ 'mobile-fixed': mobile }">
    <div class="container-fluid px-0">
      <div class="row w-100 mx-0">
        <div class="col-11 px-0 mx-auto d-flex align-items-center flex-shrink-3 justify-content-between">
          <router-link class="navbar-brand d-flex  me-0 justify-content-start" :to="{ name: 'home' }">
            <div class="w-100">
              <img :src="this.logo" :alt="name" class="logo" />
            </div>
          </router-link>
          <div class="scrollable-container" :class="{ active: isActive }">
            <ul class="navbar-nav mb-2 mb-lg-0 px-0 justify-content-center navigation-links" @click="isActive=false"> 
              <li class="nav-item" >
                <router-link :to="{ name: 'home' }" class="nav-link f-14" :class="{ active: currentRouteName == 'home' }">
                  {{ $t("home") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'categories' }" class="nav-link f-14"
                  :class="{ active: currentRouteName == 'categories' }">
                  {{ $t("categories") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'aboutus' }" class="nav-link f-14"
                  :class="{ active: currentRouteName == 'aboutus' }">
                  {{ $t("about") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'faq' }" class="nav-link f-14" :class="{ active: currentRouteName == 'faq' }">
                  {{ $t("faq") }}
                </router-link>
              </li>
              <div class="d-mobile-none">
                <li class="nav-item">
                  <router-link :to="{ name: 'terms-and-conditions' }" class="nav-link f-14">
                    {{ $t("terms") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'return-and-refund-policy' }" class="nav-link f-14">
                    {{ $t("return-policy") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'privacy-policy' }" class="nav-link f-14">
                    {{ $t("privacy-policy") }}
                  </router-link>
                </li>
              </div>
            </ul>
          </div>
          <div class="d-flex align-items-center" v-if="mobile">
           
            <div class="dropdown">
              <button class="btn secondary dropdown-toggle pointer d-flex align-items-center f-16" type="button"
                id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {{ currentCurrency.code }}
                <i class="fa fa-angle-down primary-font mx-2" aria-hidden="true"></i>
              </button>
              <ul class="dropdown-menu py-0" aria-labelledby="dropdownMenuButton1">
                <li v-for="(currency, i) in currencies" :key="currency.id">
                  <hr class="my-0" v-if="i > 0" />
                  <p class="dropdown-item f-14 py-2 mb-0" @click="changeCurrency(currency.id)">
                    {{ currency.name }}
                  </p>
                </li>
              </ul>
            </div>
            <div class="close pointer" @click="openMenu" :class="{ active: isActive }">
              <div class="line primary-bg mb-1 top"></div>
              <div class="line primary-bg bottom"></div>
              <div class="line"></div>
            </div>
          </div>


          <div class="action-links d-flex me-auto justify-content-end align-items-center" v-else>
            <div @click="switchLang(switchTo)"
              class="link-icon pointer lang position-relative d-flex justify-content-center">
              <div class="img-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                  <path id="Globe"
                    d="M14.5,3a11.512,11.512,0,1,0,8.128,3.372A11.5,11.5,0,0,0,14.5,3ZM11.584,18.923h5.832a13.325,13.325,0,0,1-2.916,5.3A13.324,13.324,0,0,1,11.584,18.923Zm-.4-1.769a16.112,16.112,0,0,1,0-5.308h6.635a16.112,16.112,0,0,1,0,5.308ZM4.769,14.5a9.687,9.687,0,0,1,.368-2.654H9.39a17.887,17.887,0,0,0,0,5.308H5.137A9.687,9.687,0,0,1,4.769,14.5Zm12.647-4.423H11.584a13.324,13.324,0,0,1,2.916-5.3A13.325,13.325,0,0,1,17.416,10.077Zm2.194,1.769h4.253a9.748,9.748,0,0,1,0,5.308H19.61A17.892,17.892,0,0,0,19.61,11.846Zm3.556-1.769H19.248A15.745,15.745,0,0,0,17.008,5.1a9.772,9.772,0,0,1,6.158,4.976ZM11.992,5.1a15.745,15.745,0,0,0-2.24,4.976H5.834A9.772,9.772,0,0,1,11.992,5.1ZM5.834,18.923H9.752a15.745,15.745,0,0,0,2.24,4.976,9.772,9.772,0,0,1-6.158-4.976ZM17.008,23.9a15.745,15.745,0,0,0,2.24-4.976h3.918A9.772,9.772,0,0,1,17.008,23.9Z"
                    transform="translate(-3 -3)" fill="#656565" />
                </svg>
              </div>
              <p class="f-14 regular position-absolute t-0 b-0 link-title text-uppercase">
                {{ switchToText }}
              </p>
            </div>
            <div class="link-icon pointer search position-relative d-flex justify-content-center" @click="OpenSearch">
              <div class="img-container d-flex my-auto">
                <div class="icon-link">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22.473" height="22.456" viewBox="0 0 22.473 22.456">
                    <path id="Outline"
                      d="M2.289,22.192l4.1-4.062a9.935,9.935,0,1,1,1.534,1.534L3.857,23.727a1.1,1.1,0,0,1-1.568-1.534Zm11.823-2.528A7.727,7.727,0,1,0,8.648,17.4,7.727,7.727,0,0,0,14.112,19.664Z"
                      transform="translate(-2.013 -1.559) rotate(-1)" fill="#656565" />
                  </svg>
                </div>
                <p class="f-14 regular position-absolute t-0 b-0 link-title">
                  {{ $t("search") }}
                </p>
              </div>
            </div>
            <router-link :to="{ name: 'cart' }" :class="{ 'nav-active-link': currentRouteName == 'cart' }"
              class="link-icon cart position-relative d-flex justify-content-center">
              <div class="img-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23.895" viewBox="0 0 20 23.895">
                  <path id="Bag"
                    d="M15.612,9.4a2.982,2.982,0,0,1,5.963,0v.385H15.612Zm-1.826,2.211V13.3a.913.913,0,0,0,1.826,0V11.612h5.963V13.3a.913.913,0,0,0,1.826,0V11.612h2.137L26.7,26.663h-16.2L11.65,11.612Zm0-1.826V9.4a4.807,4.807,0,1,1,9.615,0v.385h2.982a.913.913,0,0,1,.91.843l1.3,16.876a.913.913,0,0,1-.91.983H9.507a.913.913,0,0,1-.91-.983l1.3-16.876a.913.913,0,0,1,.91-.843Z"
                    transform="translate(-8.594 -4.594)" fill="#656565" fill-rule="evenodd" />
                </svg>
              </div>
              <p class="f-14 regular position-absolute t-0 b-0 link-title">
                {{ $t("cart") }}
              </p>
            </router-link>
            <router-link :to="{ name: 'profile-default' }" :class="{
              'nav-active-link':
                [
                  'profile-default',
                  'orders',
                  'index',
                  'create-address',
                  'address',
                  'order',
                ].indexOf(currentRouteName) !== -1,
            }" class="link-icon account position-relative d-flex justify-content-center">
              <div class="img-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23.895" viewBox="0 0 20 23.895">
                  <path id="User-Outline"
                    d="M14.314,10.7a4.28,4.28,0,1,1,4.28,4.28A4.28,4.28,0,0,1,14.314,10.7Zm4.28-6.105A6.105,6.105,0,1,0,24.7,10.7,6.105,6.105,0,0,0,18.594,4.594ZM10.419,25.412c0-1.908,1.5-5.362,3.306-5.362h9.736c1.8,0,3.306,3.454,3.306,5.362v1.251H10.419Zm3.306-7.187c-2.856,0-5.132,4.315-5.132,7.187v2.164a.913.913,0,0,0,.913.913H27.681a.913.913,0,0,0,.913-.913V25.412c0-2.872-2.276-7.187-5.132-7.187Z"
                    transform="translate(-8.594 -4.594)" fill="#656565" fill-rule="evenodd" />
                </svg>
              </div>
              <p class="f-14 regular position-absolute t-0 b-0 link-title">
                {{ $t("account") }}
              </p>
            </router-link>
            <div class="dropdown">
              <button class="btn secondary dropdown-toggle pointer d-flex align-items-center f-16" type="button"
                id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {{ currentCurrency.code }}
                <i class="fa fa-angle-down primary-font mx-2" aria-hidden="true"></i>
              </button>
              <ul class="dropdown-menu py-0" aria-labelledby="dropdownMenuButton1">
                <li v-for="(currency, i) in currencies" :key="currency.id">
                  <hr class="my-0" v-if="i > 0" />
                  <p class="dropdown-item f-14 py-2 mb-0" @click="changeCurrency(currency.id)">
                    {{ currency.name }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <teleport to=".pagger" v-if="isPopup" :disabled="!isPopup">
    <div class="popup" :class="{ 'display animate__animated animate__fadeIn': isPopup }">
      <SearchPopup />
    </div>
  </teleport>
  <div class="container-fluid bg-white ltr" v-if="mobile" id="footer"
    :class="{ 'position-fixed b-0 border-radius-27 z-index-3 shadow-sm': mobile }">
    <nav class=" mobile-fixed-nav w-100 px-0 mx-0 container-fluid" v-if="mobile">
      <div class="row h-100">
        <div class="col-lg-11 px-lg-0 col-12  m-auto">
          <div class="d-flex justify-content-around">
            <div @click="switchLang(switchTo)"
              class="link-icon  pointer lang position-relative d-flex justify-content-center">
              <div class="img-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                  <path id="Globe"
                    d="M14.5,3a11.512,11.512,0,1,0,8.128,3.372A11.5,11.5,0,0,0,14.5,3ZM11.584,18.923h5.832a13.325,13.325,0,0,1-2.916,5.3A13.324,13.324,0,0,1,11.584,18.923Zm-.4-1.769a16.112,16.112,0,0,1,0-5.308h6.635a16.112,16.112,0,0,1,0,5.308ZM4.769,14.5a9.687,9.687,0,0,1,.368-2.654H9.39a17.887,17.887,0,0,0,0,5.308H5.137A9.687,9.687,0,0,1,4.769,14.5Zm12.647-4.423H11.584a13.324,13.324,0,0,1,2.916-5.3A13.325,13.325,0,0,1,17.416,10.077Zm2.194,1.769h4.253a9.748,9.748,0,0,1,0,5.308H19.61A17.892,17.892,0,0,0,19.61,11.846Zm3.556-1.769H19.248A15.745,15.745,0,0,0,17.008,5.1a9.772,9.772,0,0,1,6.158,4.976ZM11.992,5.1a15.745,15.745,0,0,0-2.24,4.976H5.834A9.772,9.772,0,0,1,11.992,5.1ZM5.834,18.923H9.752a15.745,15.745,0,0,0,2.24,4.976,9.772,9.772,0,0,1-6.158-4.976ZM17.008,23.9a15.745,15.745,0,0,0,2.24-4.976h3.918A9.772,9.772,0,0,1,17.008,23.9Z"
                    transform="translate(-3 -3)" fill="#656565" />
                </svg>
              </div>
              <p class="f-14 regular position-absolute t-0 b-0 link-title text-uppercase">
                {{ switchToText }}
              </p>
            </div>
            <div class=" pointer search position-relative d-flex justify-content-center" @click="OpenSearch">
              <div class="img-container d-flex my-auto">
                <svg xmlns="http://www.w3.org/2000/svg" width="22.473" height="22.456" viewBox="0 0 22.473 22.456">
                  <path id="Outline"
                    d="M2.289,22.192l4.1-4.062a9.935,9.935,0,1,1,1.534,1.534L3.857,23.727a1.1,1.1,0,0,1-1.568-1.534Zm11.823-2.528A7.727,7.727,0,1,0,8.648,17.4,7.727,7.727,0,0,0,14.112,19.664Z"
                    transform="translate(-2.013 -1.559) rotate(-1)" fill="#656565" />
                </svg>
              </div>
            </div>
            <router-link :to="{ name: 'home' }" :class="{ 'nav-active-link': currentRouteName == 'home' }"
              class="link-icon home position-relative d-flex justify-content-center">
              <div class="img-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="23.042" height="24" viewBox="0 0 23.042 24">
                  <g id="Group_53560" data-name="Group 53560" transform="translate(-11)">
                    <path id="House"
                      d="M26.422,12.573l-9.6-9.058L16.808,3.5a1.92,1.92,0,0,0-2.584,0l-.013.013L4.62,12.573A1.92,1.92,0,0,0,4,13.987V25.082A1.92,1.92,0,0,0,5.92,27h5.761a1.92,1.92,0,0,0,1.92-1.92V19.321h3.84v5.761A1.92,1.92,0,0,0,19.361,27h5.761a1.92,1.92,0,0,0,1.92-1.92V13.987a1.92,1.92,0,0,0-.62-1.414Zm-1.3,12.509H19.361V19.321a1.92,1.92,0,0,0-1.92-1.92H13.6a1.92,1.92,0,0,0-1.92,1.92v5.761H5.92V13.987l.013-.012L15.521,4.92l9.589,9.052.013.012Z"
                      transform="translate(7 -3.002)" fill="#656565" />
                  </g>
                </svg>
              </div>
            </router-link>
            <router-link :to="{ name: 'profile-default' }" :class="{
              'nav-active-link':
                [
                  'profile-default',
                  'orders',
                  'index',
                  'create-address',
                  'address',
                  'order',
                  'login',
                  'register'
                ].indexOf(currentRouteName) !== -1,
            }" class="link-icon account position-relative d-flex justify-content-center">
              <div class="img-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23.895" viewBox="0 0 20 23.895">
                  <path id="User-Outline"
                    d="M14.314,10.7a4.28,4.28,0,1,1,4.28,4.28A4.28,4.28,0,0,1,14.314,10.7Zm4.28-6.105A6.105,6.105,0,1,0,24.7,10.7,6.105,6.105,0,0,0,18.594,4.594ZM10.419,25.412c0-1.908,1.5-5.362,3.306-5.362h9.736c1.8,0,3.306,3.454,3.306,5.362v1.251H10.419Zm3.306-7.187c-2.856,0-5.132,4.315-5.132,7.187v2.164a.913.913,0,0,0,.913.913H27.681a.913.913,0,0,0,.913-.913V25.412c0-2.872-2.276-7.187-5.132-7.187Z"
                    transform="translate(-8.594 -4.594)" fill="#656565" fill-rule="evenodd" />
                </svg>
              </div>
            </router-link>
            <router-link :to="{ name: 'cart' }" :class="{ 'nav-active-link': currentRouteName == 'cart' }"
              class="link-icon cart position-relative d-flex justify-content-center">
              <div class="img-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23.895" viewBox="0 0 20 23.895">
                  <path id="Bag"
                    d="M15.612,9.4a2.982,2.982,0,0,1,5.963,0v.385H15.612Zm-1.826,2.211V13.3a.913.913,0,0,0,1.826,0V11.612h5.963V13.3a.913.913,0,0,0,1.826,0V11.612h2.137L26.7,26.663h-16.2L11.65,11.612Zm0-1.826V9.4a4.807,4.807,0,1,1,9.615,0v.385h2.982a.913.913,0,0,1,.91.843l1.3,16.876a.913.913,0,0,1-.91.983H9.507a.913.913,0,0,1-.91-.983l1.3-16.876a.913.913,0,0,1,.91-.843Z"
                    transform="translate(-8.594 -4.594)" fill="#656565" fill-rule="evenodd" />
                </svg>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </nav>
    <div class="row position-relative powerd-by">
      <div class="powerd-by-bg position-absolute t-0 b-0 l-0 r-0 w-100 h-100"></div>

      <div class="col-12 my-2 position-relative z-index-1">

        <div class="d-flex justify-content-center align-items-center">
          <p class="mb-0 mx-2 f-14">
            Powered By
          </p>
          <img src="../../assets/img/static/ecart-logo.svg" alt="ecart logo">
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import LocaleHelper from "@/helpers/LocaleHelper";
import CurrencyHelper from "@/helpers/CurrencyHelper";
import SearchPopup from "../features/shared/SearchPopup.vue";

export default {
  data() {
    return {
      screen: window.innerWidth,
      isActive: false,
      switchTo: LocaleHelper.getCurrentLocale() == "ar" ? "en" : "ar",
      switchToText: LocaleHelper.getCurrentLocale() == "ar" ? "en" : "العربية",
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  emits: ['ar', 'en'],
  props: ["logo", "name", "currencies", "currency"],
  computed: {
    mobile() {
      return this.$store.getters.getMobile;
    },
    currentRouteName() {
      return this.$route.name;
    },
    currentCurrency() {
      return this.$store.getters.getCurrentCurrency;
    },
    isPopup() {
      return this.$store.getters.getSearchPopup;
    },
  },
  methods: {
    OpenSearch() {
      this.$store.dispatch("setSearchPopup", true);
    },
    switchLang(code) {
      this.$store.dispatch("setLoader", true);
      this.$store.dispatch("setCurrentLocale", code);
      location.reload();
      this.$emit(code);
      this.switchTo = code == "ar" ? "en" : "ar";
      this.switchToText = code == "ar" ? "en" : "العربية";
    },
    changeCurrency(id) {
      var c = this.currencies.find((method) => method.id == id);
      this.$store.dispatch("setLoader", true);
      this.$store.dispatch("setCurrentCurrency", c);
      CurrencyHelper.changeCurrency(JSON.stringify(c));
      location.reload();
    },
    openMenu() {
      this.isActive = !this.isActive;
      // document.querySelector('.navigation-links').style.right = 0;
    },
    onResize() {
      this.screen = window.innerWidth;
    },
  },
  components: {
    SearchPopup,
  },
};
</script>
<style scoped>
.navbar {
  z-index: 10;
  transition: all 0.4s ease-in-out;
}

.opacity-animate {
  animation: mymove 0.3s;
}

@keyframes mymove {
  from {
    top: -100px;
  }

  to {
    top: 0px;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primary-color);
  color: white !important;
}

.dropdown-toggle {
  padding-left: 10px !important;
  padding-right: 10px !important;
  border: none !important;
  outline: none !important;
}

.dropdown-toggle::after {
  display: none;
}

.english .dropdown-menu[data-bs-popper] {
  right: 0 !important;
  left: auto !important;
  text-align: left;
}

.arabic .dropdown-menu[data-bs-popper] {
  left: 0 !important;
  right: auto !important;
  text-align: right;
}

.dropdown-menu[data-bs-popper] {
  width: fit-content;
  min-width: 10px;
  top: 148% !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 0px 0px 6px 6px !important;
}

.normal .d-mobile-none {
  display: none;
}

.logo {
  max-height: 100%;
  max-width: 100%;
}

.mobile-fixed {
  position: fixed;
  z-index: 10;
  width: 100%;
}

.navbar {
  background-color: #fefefe !important;
}

.line {
  border-radius: 80px;
  width: 22px;
  height: 2px;
}

.action-links,
.scrollable-container,
.navbar-brand {
  width: 33.33333%;
}

.navbar-brand {
  max-height: 9vh;
}

.navbar {
  border-bottom: 1px solid #c9c9c9;
  height: 12vh;
}

.img-container {
  padding: 0rem 1rem;
}

.link-icon {
  height: 100%;
}

.nav-link {
  padding: 0rem 2.5rem;
}

.nav-item {
  margin: 0rem 0.9rem;
}

.arabic .nav-item:first-child,
.arabic .nav-link:first-child {
  margin-right: 0rem;
  padding-right: 0 !important;
}

.english .nav-item:first-child,
.english .nav-link:first-child {
  margin-left: 0rem;
  padding-left: 0 !important;
}

.nav-item:last-child,
.nav-link:last-child {
  margin-left: 0rem;
  padding-left: 0 !important;
}

.link-title {
  opacity: 0;
  z-index: -1;
}

.link-icon:hover .link-title {
  opacity: 1;
  z-index: 0;
  transition: all 0.3s ease-in-out;
  top: 100%;
}

.search:hover #Outline {
  fill: var(--primary-color);
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
}

.custom-menu-details {
  left: 0;
  top: 100%;
  right: 0;
  width: -moz-max-content;
  width: max-content;
  background-color: white;
  border: 1px solid #ededed;
}

.triangle {
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-top: 0;
  border-bottom: 30px solid white;
}


.z-index-3 {
  z-index: 10;
}

.border-radius-27 {
  border-radius: 27px 27px 0px 0px;
  border: 1px solid #ededed;
}

.mobile-fixed-nav {
  height: 10vh;
  bottom: 0;
}

.bg-white {
  background-color: white;
}

.powerd-by-bg {
  z-index: 0;
  background-color: var(--primary-color);
  opacity: 15%;
}

@media (max-width: 575.98px) {
  .navbar-brand {
    max-height: 9vh !important;
  }

  .nav-item {
    margin-bottom: 9% !important;
  }
}

@media (max-width: 1049.98px) {

  .navbar-expand-xl .navbar-nav,
  .navbar-nav {
    flex-direction: column !important;
  }

  .navbar {
    height: 10vh;
  }

  .navbar-brand {
    max-height: 6vh;
  }

  .border-radius-27 {
    border-radius: 27px 27px 0px 0px !important;
  }


  .border-radius-27 {
    border-radius: 55px 55px 0px 0px;
  }

  .scrollable-container {
    overflow: hidden;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    height: 88vh;
    /* overflow-y: auto; */
    position: absolute;
    width: 100vw;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.14);
    backdrop-filter: blur(30px);
    justify-content: center;
    text-align: center;
    top: 0;
    left: auto;
    right: 100%;
    transition: all 0.3s ease-in-out;
  }

  .scrollable-container.active {
    right: 0;
    left: 0
  }

  .navigation-links {
    width: 100%;
    max-height: 70%;
    overflow-y: auto;
    justify-content: flex-start !important;
  }

  .close {
    z-index: 3;
    position: relative;
  }

  .close.active {
    position: relative;
    z-index: 3;
  }

  .nav-link,
  .nav-item {
    padding-right: 0 !important;
    padding-left: 0 !important;

    margin-left: 0 !important;
    margin-right: 0 !important;

    font-size: 14px !important;
    font-weight: 500;
  }

  .nav-item {
    margin-bottom: 4% ;
  }

  .nav-item:last-child {
    margin-bottom: 0% !important;
  }

  .active .top {
    transform: rotate(45deg);
    margin: 0 !important;
    transform-origin: center;
    transition: all 0.3s ease-in-out;
    position: absolute;
  }

  .active .bottom {
    transform: rotate(-45deg);
    transform-origin: center;
    transition: all 0.3s ease-in-out;
    position: absolute;
  }

  .link-title {
    opacity: 0;
    /* z-index: 0;
    top: 100%; */
  }

  .img-container {
    background-color: #fefefe;
  }

  .navbar {
    padding: 0;
  }
  .dropdown-menu[data-bs-popper]{
    top: 122% !important;
  }  .powerd-by{
    height:6vh
  }

}

@media (min-width: 1050px) {


  .navbar-expand-xl .navbar-nav,
  .navbar-nav {
    flex-direction: row !important;
  }

  .arabic .m-end-auto {
    margin-left: auto;
    margin-right: 0;
  }

  .english .m-end-auto {
    margin-right: auto;
    margin-left: 0;
  }

}</style>
