import RepositoryFactory from '@/repositories/RepositoryFactory'
import Product from '@/models/Product'
import store from '@/store/index'
import Search from '@/models/Search'

export default class ProductService {
  constructor () {
    this.products = RepositoryFactory.get('products')
  }

  async get (productId) {
    store.dispatch('setLoader', true)
    return this.products
      .get(productId)
      .then(response => {
        let product = new Product(response.data.data)
        return Promise.resolve(product)
      })
      .catch(error => {
        return Promise.resolve(null)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }

  async getFeatured () {
    store.dispatch('setLoader', true)
    return this.products
      .getFeatured()
      .then(response => {
        let products = []
        response.data.data.forEach(function (product) {
          let prod = new Product({ product: product })
          products.push(prod)
        })
        return Promise.resolve(products)
      })
      .catch(error => {
        return Promise.resolve(null)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }
  async search (params) {
    store.dispatch('setLoader', true)
    return this.products
      .search(params)
      .then(response => {
       let result = new Search(response.data.data) 

        return Promise.resolve(result)
      })
      .catch(error => {
        return Promise.resolve(null)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }
}
