import RepositoryFactory from '@/repositories/RepositoryFactory'
import Settings from '@/models/cms/Settings'
import Footer from '@/models/cms/Footer'
import Header from '@/models/cms/cmsBlock/Header'
import Categories from '@/models/cms/cmsBlock/Categories'
import Products from '@/models/cms/cmsBlock/Products'
import Ads from '@/models/cms/cmsBlock/Ads'
import Category from '@/models/Category'
import store from '@/store/index'
import Currency from '@/models/cms/Currency'

export default class CMSService {
  constructor () {
    this.CMS = RepositoryFactory.get('CMS')
  }

  async getSettings () {
    store.dispatch('setLoader', true)
    return this.CMS.getSettings()
      .then(response => {
        let settings = new Settings(response.data.data.settings)
        let footer = new Footer(response.data.data.footerData)
        let store_settings = {
          footer: footer,
          settings: settings
        }
        return Promise.resolve(store_settings)
      })
      .catch(error => {
        console.log(error)
        Promise.resolve(null)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }

  async CMSBlocks () {
    store.dispatch('setLoader', true)
    return this.CMS.getCMSBlocks()
      .then(response => {
        // let CMSBlocks
        let blocks = []
        response.data.data.forEach(function (block) {
          switch (block.name) {
            case 'Header':
              let header = new Header(block)
              blocks.push(header)
              break
            case 'Categories':
              let categories = new Categories(block)
              let cats = []
              let res = {}
              block.categories.forEach(function (category) {
                let cat = new Category({ category })
                cats.push(cat)
              })
              res = categories
              res.list = cats
              blocks.push(res)
              break
            case 'Products':
              let products = new Products(block)
              blocks.push(products)
              break
            case 'Ads':
              let ads = new Ads(block)
              blocks.push(ads)
              break
          }
        })
        return Promise.resolve(blocks)
      })
      .catch(error => {
        Promise.resolve(null)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }

  async getCurrencies () {
    store.dispatch('setLoader', true)
    return this.CMS.getCurrencies()
      .then(response => {
        var currencies = []
        response.data.data.forEach(function (currency) {
          var c = new Currency(currency)
          currencies.push(c)
        })
        return Promise.resolve(currencies)
      })
      .catch(error => {
        return Promise.resolve(null)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }
}
