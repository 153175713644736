<template>
    <section id="search-popup"
        class="position-fixed bg-transperant-black l-0 r-0 t-0 b-0 d-flex justify-content-center align-items-center">
        <div class="bg-white w-400px p-xl-4 py-3 px-2 options-card">
            <div class="d-flex justify-content-between align-items-center">
                <h5 class="f-18 semibold primary-font mb-xl-4 mb-2">
                    {{ $t('search') }}
                </h5>
                <div class="close pointer active mb-xl-4 mb-2 p-3" @click="close">
                    <div class="line grey-bg mb-1 top"></div>
                    <div class="line grey-bg bottom"></div>
                    <div class="line "></div>
                </div>
            </div>
            <div class="options">
              <SearchBar/>
            </div>
        </div>
    </section>
</template>
<script>
import SearchBar from '@/components/UI/SearchBar.vue'

export default {
    methods: {
        close() {
            this.$store.dispatch('setSearchPopup',false);
        }
    },
  
    components: { SearchBar }
}
</script>
<style scoped>

.active .top {
transform: rotate(45deg);
margin: 0 !important;
transform-origin: center;
transition: all .3s ease-in-out;
position: absolute;

}

.active .bottom {
transform: rotate(-45deg);
transform-origin: center;
transition: all .3s ease-in-out;
position: absolute;

}

.line {
border-radius: 80px;
width: 22px;
height: 2px;
}

.store-btn {
border-radius: 50px;
min-width: 30%;
width: fit-content;
color: white;
height: 45px;
margin: auto;
text-align: center;
}

#search-popup {
z-index: 100;
}

.grey-light {
color: #c4c4c4 !important;
}

.w-400px {
width: 500px;
}

.bg-transperant-black {
background: rgba(0, 0, 0, 0.362);
}

.bg-white {
background-color: white;
border-radius: 9px;
}

.radio-circle {
border-radius: 50px;
border: 2px solid var(--primary-color);
height: 22px;
width: 22px;
}

.checked .point {
border-radius: 50px;
background-color: var(--primary-color);
height: 10px;
width: 10px;
}

.option-card {
background-color: white;

border-radius: 10px;
}

.grey-bg {
background: grey;
}

#search-popup {
opacity: 0;
transition: all .5s ease-in-out;
}

.display #search-popup {
opacity: 1;
transition: all .5s ease-in-out;
}
@media (max-width: 1049.98px) {
    .w-400px {
    max-width: 90%;
    width: 500px;

}
.line {
border-radius: 80px;
width: 18px;
height: 2px;
}
}
</style>