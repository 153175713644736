export default {
  setMobile ({ commit }, isMobile) {
    commit('Mobile', isMobile)
  },  
  setLgXlGap ({ commit }, isGap) {
    commit('LgXlGap', isGap)
  },
  setScreen ({ commit }) {
    commit('Screen')
  },
  setLoader({commit},load){
    commit('Loader',load)
  },   
  setStoreName({commit},name){
    commit('Store',name)
  },
  setCurrentLocale({commit},local){
    commit('CurrentLocale',local)
  },       
  setCurrentCurrency({commit},currency){
    commit('CurrentCurrency',currency)
  },  
  setAddressCount({commit},count){
    commit('AddressCount',count)
  },
  setSearchPopup({commit},val){
    commit('SearchPopup',val)
  }    
}
