import cookiesHelper from '@/helpers/CookiesHelper'

const Currency_KEY = 'currency'

export const changeCurrency = currencyCode => {
  cookiesHelper.set(Currency_KEY, currencyCode)
}

export const getCurrentCurrency = () => {
  const currency = cookiesHelper.get(Currency_KEY);
  return currency ? currency : ''
}
export const getCurrentCurrencyId = () => {
    const currency = cookiesHelper.get(Currency_KEY);
    return currency ? currency.id : ''
  }
  
export default {
    getCurrentCurrency,
    changeCurrency,
    getCurrentCurrencyId
}
