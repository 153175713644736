import CurrencyHelper from "@/helpers/CurrencyHelper"
import LocaleHelper from "@/helpers/LocaleHelper"
export default {
  getMobile (state) {
    return state.mobile
  },  
  getLgXlGap (state) {
    return state.LgXlGap 
  },
  getLoader (state) {
    return state.loader
  },
  getAddressCount (state) {
    return state.addressCount
  },
  getScreen (state) {
    return window.innerWidth
  },
  getStoreName(state){
    return state.storeName
  },
  getCurrentLocale(){
    return LocaleHelper.getCurrentLocale()
  },
  getCurrentCurrency(state){
    return CurrencyHelper.getCurrentCurrency()
  },
  getSearchPopup (state) {
    return state.searchPopup
  },
}