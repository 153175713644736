import CheckoutService from "@/services/CheckoutService";
import CartService from "@/services/CartService";
import ProductService from "@/services/ProductService";
import CMSService from "@/services/CMSService";
import CategoryService from "@/services/CategoryService";
import AuthService from "./AuthService";
import CustomerService from "@/services/CustomerService"

const services = {
    checkout: new CheckoutService(),
    cart: new CartService(),
    products: new ProductService(),
    category : new CategoryService(),
    CMS : new CMSService(),
    auth : new AuthService(),
    customer: new CustomerService()
};

export default {
    get: (service) => services[service],
};