export default class Settings {
    constructor(settings) {
        this.fonts = settings.fonts,
        this.primary_color = settings.primaryColor,
        this.secondary_color = settings.secondaryColor,
        this.default_lang = settings.defaultLang
        this.logo = settings.logo,
        this.company_name = settings.companyName,
        this.secondary_color = settings.secondaryColor,
        this.available_langs = settings.availableLangs,
        this.currency = settings.currency,
        this.css = settings.css ,
        this.footer_js = settings.footer_js,
        this.header_js = settings.header_js
    }
}
