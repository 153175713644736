<template>
    <div class="counter primary-border mx-auto d-flex align-items-center justify-content-around">
        <p class="pointer  mb-0" type="button" @click="add()">+</p>
        <p class="mb-0">{{ q }}</p>
        <p class="  mb-0 pointer"   @click="min()">-</p>
    </div>
</template>
<script>
export default {
    emits: ['qty'],
    data() {
        return {
            q: parseInt(this.qty),
            timerCount: 0,
            enable: false
        }
    },
    props: {
        qty: {
            type: String,
            default: '1'
        },
        counterType: {
            type: String,
            default: 'cart'
        }
    },

    methods: {
        add() {
            this.q = this.q + 1;
            this.timerCount = 2;
            if (!this.enable)
                this.countDown();
        },
        min() {
            if ((this.q - 1) != 0) {
                this.q = ((this.q - 1) == 0) ? this.q : this.q - 1;
                this.timerCount = 2;
                if (!this.enable)
                    this.countDown();
            }
        },
        countDown() {
            if (this.counterType == 'cart') {
                if (this.timerCount > 0) {
                    this.enable = true;
                    setTimeout(() => {
                        this.timerCount--;
                        this.countDown()
                    }, 1000);
                }
                else {
                    this.enable = false;
                    this.timerCount = 2;
                    this.$emit('qty', this.q);
                    return;
                }
            } else if (this.counterType == 'product') {
                this.$emit('qty', this.q);
            }

        }
    }
}
</script>
<style scoped>
.hidden {
    visibility: hidden;
}

.counter p {
    user-select: none;
}

.counter {
    height: 36px;
    max-width: 100%;
    width: 100px;
    border: 1px solid;
    border-radius: 50px;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:active,
:not(.btn-check)+.btn:active {
    border: none !important;
    background-color: unset !important;
    box-shadow: none !important;
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 0%);
}
</style>