<template>
    <router-link :to="{ name: 'categories' }">
        <div class="category-card position-relative text-center d-flex justify-content-center my-4">
            <div class="d-flex align-items-center justify-content-center">
                <div class="rounded-squer secondary-bg d-flex align-items-center">
                    <p class="dynamic-text w-fit-content d-flex m-auto f-18 semibold text-capitalize ">{{ title ??
                        default_title }}</p>
                </div>
            </div>
            <p class="text w-fit-content justify-content-center d-flex m-auto f-20 semibold position-absolute t-0 b-0">
                {{ $t('browse-cats') }}
            </p>
        </div>
    </router-link>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            default_title: this.$t('cat-unique')
        }
    }
}
</script>
<style scoped>
.category-card {
    width: fit-content;
    overflow: hidden;
}

.rounded-squer {
    height: 180px;
    width: 180px;
    border-radius: 22px;
    background-size: cover;
    background-position: center;
    background-image: url('../../../assets/img/static/decoration-1.png');
    background-size: contain;
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
}

.dynamic-text,
.text {
    color: white !important;
    width: fit-content;
    height: fit-content;
    width: 90%;

}

.dynamic-text {
    color: white !important;
    transition: all .4s ease-in-out;
}

.text {
    right: -150%;
    transition: all .2s ease-in-out;
    opacity: 100%;
}

.category-card:hover .text {
    right: 0;
    left: 0;
    transition-delay: .4s;
    transition: all .4s ease-in-out;
}

.category-card:hover .dynamic-text {
    padding-top: 100%;
    transition: all .3s ease-in-out;
}
</style>