<template>
  <BlindLoader v-if="loading" />
  <div :class="[locale == 'en' ? 'english ' : 'arabic ', mobile ? 'mobile ' : 'normal ']"
    v-else-if="!loading && !errorOcure && settings.company_name" class="pagger d-flex flex-column justify-content-between"
    id="pagger">
    <div class="d-none" id="lang-switcher" v-if="($i18n.locale = this.locale) == 'ab'">
    </div>
    <TheNavbar :currencies="currencies" :logo="settings.logo" :name="settings.company_name" :currency="settings.currency"
      @en="changeLocale('en')" @ar="changeLocale('ar')" />
    <div class="body" id="body-wrapper" :class="{ 'spacing': mobile }">
      <router-view />
      <TranspearentLoader v-if="this.$store.getters.getLoader" />
    </div>
    <TheFooter :footer="footer" :logo="settings.logo" :name="settings.company_name" />
  </div>
  <Error v-else-if="!loading && errorOcure" />
</template>
<script>
import TranspearentLoader from '@/components/UI/TranspearentLoader.vue'

import TheNavbar from '@/components/layouts/TheNavbar.vue'
import Error from '@/components/UI/Error.vue'
import TheFooter from '@/components/layouts/TheFooter.vue'
import LocaleHelper from "@/helpers/LocaleHelper";
import BlindLoader from '@/components/UI/BlindLoader.vue'
import ServiceFactory from "@/services/ServiceFactory";
import CurrencyHelper from './helpers/CurrencyHelper'

const CMS = ServiceFactory.get("CMS");

export default {
  data() {
    return {
      lang: 'en',
      screen: window.innerWidth,
      loading: false,
      errorOcure: false,
      settings: {},
      currencies: [],
      footer: {
        social_media: {},
        contact_info: {},
        description: ''
      },
      socialMedia: {},
      cmsBlocks: {}
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getSettings();
    this.$store.dispatch('setMobile', window.innerWidth <= 1049.98)
    this.$store.dispatch('setLgXlGap', window.innerWidth > 1049.98 && window.innerWidth < 1140)

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  components: {
    TheNavbar,
    TheFooter,
    BlindLoader,
    Error,
    TranspearentLoader
  },
  methods: {
    async getSettings() {
      var here = this;
      this.loading = true;
      let storeSettings = await CMS.getSettings();
      let curr = await CMS.getCurrencies();
      if (curr) {
        this.currencies = curr;
      }
      else {
        this.loading = false;
      }
      if (storeSettings) {
        this.settings = storeSettings.settings;
        this.$store.dispatch('setStoreName', this.settings.company_name);
        this.footer = storeSettings.footer;
        this.socialMedia = storeSettings.social_media;
        this.setCss(this.settings.css);
        this.setjs(this.settings.header_js);
        this.setjs(this.settings.footer_js);

        this.changeCssVariblesValue();
        this.loading = false;
        if (LocaleHelper.getCurrentLocale() == undefined || LocaleHelper.getCurrentLocale() == '')
          here.changeLocale(here.settings.default_lang);
        else
          here.changeLocale(LocaleHelper.getCurrentLocale());
        var currenctCurrency = CurrencyHelper.getCurrentCurrency();
        if (currenctCurrency == undefined || currenctCurrency == '')
          here.changeCurrency(here.settings.currency);
        else if (currenctCurrency.id == here.settings.currency.id && currenctCurrency.name == here.settings.currency.name)
          here.changeCurrency(here.$store.getters.getCurrentCurrency);
        else if (currenctCurrency.id == here.settings.currency.id && currenctCurrency.name != here.settings.currency.name)
          here.changeCurrency(here.settings.currency);
      }
      else {
        this.loading = false;
        this.errorOcure = true;
        if (LocaleHelper.getCurrentLocale() == '')
          here.changeLocale('ar');
      }
    },
    addFontCDN(name) {
      if (name == 'cairo')
        return;
      var head = document.head;
      var link = document.createElement("link");

      link.rel = "stylesheet";
      link.href = `https://fonts.googleapis.com/css2?family=${name}&display=swap`;

      head.appendChild(link);
    },
    changeCssVariblesValue() {
      const root_theme = document.querySelector(':root');
      root_theme.style.setProperty('--primary-color', this.settings.primary_color);
      root_theme.style.setProperty('--secondary-color', this.settings.secondary_color);
      root_theme.style.setProperty('--font-family', this.settings.fonts[this.settings.default_lang].name + ' , ' + 'sans-' + this.settings.fonts[this.settings.default_lang].type);
      this.addFontCDN(this.settings.fonts[this.settings.default_lang].name);
    },
    changeLocale(localeCode) {
      LocaleHelper.changeLocale(localeCode);
      this.$store.dispatch('setCurrentLocale', localeCode)
      this.lang = localeCode;
    },
    changeCurrency(code) {
      CurrencyHelper.changeCurrency(code);
      this.$store.dispatch('setCurrentCurrency', code)
    },
    onResize() {
      this.screen = window.innerWidth;
      this.$store.dispatch('setMobile', window.innerWidth <= 1049.98)
      this.$store.dispatch('setLgXlGap', window.innerWidth > 1049.98 && window.innerWidth < 1140)
    },
    setCss(css) {
      var head = document.head || document.getElementsByTagName('head')[0];
      var style = document.createElement('style');
      style.type = 'text/css';
      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      head.appendChild(style);
    },
    setjs(js) {
      var body = document.getElementsByTagName('body')[0];
      var script = document.createElement('script');
      script.text = `document.addEventListener('load', function() {${js}}, false);`;
      script.type = "text/javascript";
      script.defer = true
      body.appendChild(script);
    }
  },

  computed: {
    mobile() {
      return this.$store.getters.getMobile;
    },
    locale() {
      return this.$store.getters.getCurrentLocale;
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
}
</script>
<style scoped>
/* #body-wrapper {
  padding-bottom: 12vh;
} */

.body {
  background-color: #fefefe !important;
  min-height: 60vh;
}

#pagger {
  overflow-y: scroll;
}

.py-12vh {
  padding-top: 12vh;
}

@media (max-width: 1049.98px) {
  .py-12vh {
    padding-top: 10vh;
    padding-bottom: 16vh;
  }

  .spacing {
    padding-top: 10vh;
  }

  #body-wrapper {
    padding-bottom: 16vh;
  }
}
</style>


