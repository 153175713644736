export default class Header {
    constructor(header) {
        this.name = header.name,
        this.selected_option = header.selectedOption,
        this.enable = header.enable,
        this.images=header.images,
        this.enable_searchbar = header.enableSearchBar,
        this.title = header.title ,
        this.paragraph = header.paragraph,
        this.sort = header.sort 
    }
}