export default class Ads {

    constructor(ad) {
        this.name = ad.name,
        this.selected_option = ad.selectedOption,
        this.enable = ad.enable,
        this.images=ad.images,
        this.sort = ad.sort 
    }
}
