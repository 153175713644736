
import axios from "@/helpers/axios";

export default class BaseRepository {
    constructor() {
        this.http = axios;
    }
}

// const baseDomain =  "https://test.ecart.sy";
// const baseUrl = `${baseDomain}/api`
// const sharedHeaders = {
//     'Accept': 'application/json',
//     'Content-Type': 'application/json',
// };
// // if (AuthHelper.hasToken()) {
// //     sharedHeaders.Authorization = `Bearer ${AuthHelper.getToken()}`;
// // }
// export default axios.create({
//   baseURL: baseUrl,
//   headers: sharedHeaders,    
//   withCredentials: true, // To enable cookies,
// //   https: !DEBUG
// });
