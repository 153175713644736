export default class Products {
    constructor(products) {
        this.name = products.name,
        this.selected_option = products.selectedOption,
        this.enable = products.enable,
        this.products = products.products,
        this.sort = products.sort 
    }
}

