import {CartItem} from "@/models/CartItem";

export default class Cart {
    constructor(data) {
        this.id= data.cart.id,
        this.total= data.cart.total,
        this.formatted_total= data.cart.formatted_total,
        this.tax_total= data.cart.tax_total,
        this.fees_total= data.cart.fees_total,
        this.sub_total= data.cart.sub_total,
        this.discount= data.cart.discount,
        this.coupon_code= data.cart.coupon_code,
        this.formatted_discount= data.cart.formatted_discount,
        this.formatted_tax_total= data.cart.formatted_tax_total,
        this.formatted_fees_total= data.cart.formatted_fees_total,
        this.formatted_sub_total= data.cart.formatted_sub_total,
        this.count = data.count,
        // start - only for checkout summary
        this.formatted_delivery_fees =  data.cart.formatted_delivery_fees ?? null ,
        this.delivery_fees =  data.cart.delivery_fees ?? null 
        // end
        if (Array.isArray(data.items)) {
            this.items = data.items.map((item) => new CartItem(item));
        }
        else if(Array.isArray(data.cart_items)){
            this.items = data.cart_items.map((item) => new CartItem(item));
        }

    }
}
