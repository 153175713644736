<template>
        <div class="row position-relative">
            <div class="col-11 px-lg-0  mx-auto">
               <CardsSlider slidesPerView="4"/>
            </div>               
            <!-- <SearchBar position="absolute" class="w-50 mx-auto"/> -->
        </div>
</template>
<script>
import  CardsSlider from '@/components/UI/ImageCardSlider.vue'
import  SearchBar from '@/components/UI/SearchBar.vue'
export default {
    components: {
        CardsSlider,
        SearchBar
    }
};
</script>

<style scoped>
/* .row {
    background-image: url('../../../assets/img/static/bg.png');
    background-size: cover;
    background-position-y: top;
} */

</style>
 