<template>
    <div class="d-flex justify-content-between align-items-center">
        <div class="title d-flex align-items-center">
            <div class="w-6px primary-bg m-start-2 h-40px"></div>
            <h2 class="f-24 regular mb-0">{{ title }}</h2>
        </div>
        <router-link :to="{ name: routeName }" v-if="routeName.length > 3">
            <div class="d-flex primary-border out-line-btn">
                <p class="mb-0 f-16 primary-font py-2 px-3 regular">{{ routeText }}</p>
            </div>
        </router-link>

    </div>
</template>
<script>
export default {
    props: {
        routeText: {
            required: false,
            type: String
        },
        title: {
            required: true,
            type: String
        },
        routeName: {
            required: false,
            type: String,
            default: ''
        }
    },
}
</script>
<style scoped>
.english .m-start-2 {
    margin-right: .5rem;
}

.arabic .m-start-2 {
    margin-left: .5rem;
}

.w-6px {
    width: 6px;
}

.out-line-btn {
    border: 1px solid;
    border-radius: 50px;
}

.h-40px {
    height: 35px !important;
}

@media (max-width: 575.98px) {
    .f-24 {
        font-size: 20px;
    }

    .w-6px {
        width: 5px;
    }

    .h-40px {
        height: 27px !important;
    }
    .english .m-start-2 {
    margin-right: .35rem;
}

.arabic .m-start-2 {
    margin-left: .35rem;
}


}

@media (max-width: 767.98px) {}

@media (max-width: 991.98px) {}


@media (max-width: 1199.98px) {}</style>