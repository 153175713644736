const metaMixin = {
  mounted () {
    this.updateTitle('EShop Factory', 'Store')
  },
  methods: {
    updateTitle (store, title) {
      document.title = title + ' | ' + store
    },
    updateDescription (store ,desc) {
      if (document.querySelector('meta[name="description"]'))
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', desc)
      else {
        var meta = document.createElement('meta')
        meta.setAttribute('name', 'description')
        meta.setAttribute('content', `${store} | ${desc}`)
        document.getElementsByTagName('head')[0].appendChild(meta)
      }
    },
    updateKeywords (store, keys) {
      if(document
        .querySelector('meta[name="keywords"]'))
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute('content', `${store} , ${keys}`)
        else{
          var meta = document.createElement('meta')
          meta.setAttribute('name', 'keywords')
          meta.setAttribute('content', `${store} , ${keys}`)
          document.getElementsByTagName('head')[0].appendChild(meta)
        }
    }
  }
}
export default metaMixin
