<template>
    <section class="container-fluid" id="header">
        <CardsSlider  v-if="header.selected_option == 'Header_B'" />
        <CardSliderWithText v-else-if="header.selected_option == 'Header_C'" :enableSearch="header.enable_searchbar"/>
        <FullWidthSlider v-else :enableSearch="header.enable_searchbar"  />
    </section>
</template>
<script>
import FullWidthSlider from '@/components/blocks/header/FullWidthSlider.vue'
import CardsSlider from '@/components/blocks/header/CardsSlider.vue'
import CardSliderWithText from '@/components/blocks/header/CardSliderWithText.vue'
export default {
    props: {
        header: {
            type: Object,
            required: true,
            validator: function (value) {
                return 'images' in value
                    && 'selected_option' in value
                    && 'title' in value
                    && 'paragraph' in value
                    && 'enable_searchbar' in value
            }
        }
    },
  provide() {
    // use function syntax so that we can access `this`
    return {
      header: this.$props.header
    }
  } ,
    components: {
        FullWidthSlider,
        CardsSlider,
        CardSliderWithText
    }
}
</script>