export class CartItem {
    constructor(cartItem) {
        this.id = cartItem.id,
        this.name = cartItem.name,
        this.price = cartItem.price,
        this.type = cartItem.type,
        this.formatted_price  = cartItem.formatted_price,
        this.quantity=  cartItem.quantity,
        this.checkin_date = cartItem.checkin_date,
        this.checkout_date = cartItem.checkout_date,
        this.checkin_time = cartItem.checkin_time,
        this.checkout_time = cartItem.checkout_time,
        this.image = cartItem.base_image,
        this.product_id = cartItem.product_id,
        this.product_slug = cartItem.seo_slug,
        this.product_price = cartItem.formatted_product_price
    }
}