import Product from "@/models/Product"

export default class Search {
    constructor (data) {
      this.search_term = data.applied_filters.search_term
      this.product_count = data.products_count ?? 0
      this.max_price = data.price_range ? data.price_range.max : 0
      this.min_price = data.price_range ? data.price_range.min:0 
      this.less_than = data.applied_filters&&data.applied_filters.price_less_than ? data.applied_filters.price_less_than:this.max_price
    this.more_than = data.applied_filters&&data.applied_filters.price_more_than ? data.applied_filters.price_more_than:this.min_price
    this.sort_type = data.applied_filters ? data.applied_filters.sort_type : null
    if (Array.isArray(data.products)) {

        this.products = data.products;

    }
    }
  }
