<template>
    <section class="product-images d-flex  justify-content-start ">
        <div class="thumbnails d-flex flex-column m-end-3 " >
            <div class="img-thumbnail mb-2" v-for="(img, index) in images.list" :key="index"
            @click="base_image =img "
                v-bind:style="{ 'background-image': 'url(' + img + ')' }">
            </div>
        </div>
        <div class="image-container">
            <VueImageZoomer :regular="base_image" img-class="img-fluid" :alt="productName" close-pos="top-right" />
        </div>
    </section>
</template>
<script>
import { VueImageZoomer } from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';
export default {
    data(){
        return{
            base_image : this.images.base_image
        }
    },
    props: {
        images: {
            type: Object
        },
        productName: {
            type: String
        }
    },
    components: {
        VueImageZoomer
    }
}
</script>
<style scoped>

.image-container {
    width: 442px;
    max-height: 484px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    object-fit: contain;
}

.img-thumbnail {
    width: 89px;
    padding-top: 89%;
    background-size: cover;
}

.arabic .m-end-3 {
    margin-left: .8rem;
}

.english .m-end-3 {
    margin-right: .8rem;
}
@media (max-width: 575.98px) {
    .image-container {
    max-width: 75%;
    margin:auto;
}

}

@media (max-width: 767.98px) {
    
}

</style>