import BaseRepository from '@/repositories/Repository'

export default class AuthRepository extends BaseRepository{

    async login(credentials){
        return  this.http.post('login', credentials)
    }

    async register(credentials){
        return  this.http.post('register', credentials)
    }

    async logout(){
        return this.http.post('logout')
    }
}