import ProductRepository from "@/repositories/ProductRepository";
import CartRepository from "@/repositories/CartRepository";
import CategoryRepository from "@/repositories/CategoryRepository";
import CustomerRepository from "@/repositories/CustomerRepository";
import CheckoutRepository from "@/repositories/CheckoutRepository";
import CMSRepository from "@/repositories/CMSRepository";
import AuthRepository from "@/repositories/AuthRepository";

const repos = {
    products: new ProductRepository(),
    cart: new CartRepository(),
    categories: new CategoryRepository(),
    customer: new CustomerRepository(),
    checkout: new CheckoutRepository(),
    CMS : new CMSRepository() ,
    auth : new AuthRepository()
};

export default {
    get: (repo) => repos[repo],
};