
<template>
    <section id="product-details">
        <div class="title">
            <div class="categories-breadcrumb">
                <div class="category d-flex" v-for="(category, index) in product.breadcrumb" :key="category.id">
                    <span class="mx-1 color-grey f-14" v-if="index != 0">&gt;</span>
                    <router-link :to="{
                        name: 'category',
                        params: {
                            slug: category.slug,
                            category_id: category.id
                        }
                    }">
                        <p class="f-14 semibold mb-0 color-grey">
                            {{ category.name }}
                        </p>
                    </router-link>
                </div>
            </div>
            <h1 class="product-name f-24 my-xl-2 mb-0 semibold color-black pb-xl-2">
                {{ product.name }}
            </h1>
            <hr class="d-none d-xl-block">
        </div>
        <div class="price-value f-18 bold pt-2 d-block d-lg-none">
            {{ product.formatted_price }}
        </div>
        <div class="product-desc pt-2" v-if="product.html_description">
            <p class="desc-label f-16 primary-font mb-2 semibold d-none d-lg-block">
                {{ $t('product-desc') }}
            </p>
            <div class="desc-value w-lg-50 pb-2" v-html="product.html_description">
            </div>
            <hr class="d-none d-xl-block">
        </div>

        <div class="product-price pt-2 d-lg-block d-none">
            <p class="price-label f-16 primary-font mb-2 semibold d-none d-lg-block">
                {{ $t('price') }}
            </p>
            <div class="price-value f-18 bold pb-2">
                {{ product.formatted_price }}
            </div>
        </div>
        <hr class="d-none d-xl-block">

        <div class="product-quantity pt-2" v-if="product.type == 'product'">
            <p class="price-label f-16 primary-font mb-2 semibold d-none d-lg-block">
                {{ $t('quantity') }}
            </p>
            <div class="d-flex mt-md-3 ">
                <div class="w-25">
                    <QtyCounter counterType="product" @qty="saveQty" />
                </div>
                <div class="w-25">
                    <button type="button" class="btn mx-xl-5 mx-2 primary-bg white add-to-cart px-xl-4 px-2"
                        @click="addToCart">
                        {{ $t('add-to-cart') }}
                    </button>
                </div>
            </div>
        </div>
        <div class="product-date pt-2" v-else-if="product.type == 'reservation'">
            <p class="price-label f-16 primary-font mb-2 semibold d-none d-lg-block">
                {{ $t('reservation-date') }}
            </p>
            <div class="d-flex align-items-center">

                <div class="w-50 date-picker">
                    <DatePicker @reservation="setReservation" :require_hour="product.require_hour"
                        :range="product.require_end_date == 0 ? false : product.require_end_date" />
                </div>
                <div class="w-25">
                    <button type="button" class="btn mx-xl-5 mx-2 primary-bg white add-to-cart px-4" @click="addToCart">
                        {{ $t('add-to-cart') }}
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import QtyCounter from '@/components/UI/QtyCounter.vue';
import DatePicker from '@/components/features/shared/inputs/DatePicker.vue'
import ServiceFactory from '@/services/ServiceFactory';
const cart = ServiceFactory.get("cart");

export default {
    props: {
        product: Object,
    },
    data() {
        return {
            qty: '1',
            reservation: {
                checkin: '',
                checkin_time: '',
                checkout: '',
                checkout_time: ''
            }
        }
    },
    components: {
        QtyCounter,
        DatePicker
    },
    methods: {
        saveQty(payload) {
            this.qty = payload;
        },
        async addToCart() {
            if (this.product && this.product.type == 'product') {
                if (await cart.add(this.product.id, { 'quantity': this.qty }))
                    this.$swal.fire({
                        icon: 'success',
                        text: this.$t('added-to-cart'),
                        timer: 1000,
                        confirmButtonText: this.$t('ok')

                    })
            } else if (this.product && this.product.type == 'reservation') {
                if (this.reservation.checkin != '') {
                    if (await cart.add(this.product.id, this.reservation))
                        this.$swal.fire({
                            icon: 'success',
                            text: this.$t('added-to-cart'),
                            timer: 1000,
                            confirmButtonText: this.$t('ok')

                        })
                }
                else {
                    this.$swal.fire({
                        icon: 'error',
                        text: this.$t('select-date-first'),
                        confirmButtonText: this.$t('ok')

                    })
                }
            }

        },
        setReservation(payload) {
            this.reservation = payload;
        }
    },
    computed: {
        mobile() {
            return this.$store.getters.getMobile;
        },
    }
}
</script>
<style scoped>
.categories-breadcrumb {
    display: flex;
    justify-content: start;
}

.color-grey {
    color: #AEAEAE !important;
}

.color-black {
    color: #363636 !important
}

.desc-value,
.price-value {
    color: #656565 !important
}

.white {
    color: white
}

.add-to-cart {
    border-radius: 50px;
    height: 50px;
    width: 100%;
}

.add-to-cart:hover,
.add-to-cart:active,
.add-to-cart:focus {
    color: white !important;
}

@media (max-width: 1049.98px) {}


@media (max-width: 767.98px) {



    .desc-value {
        font-size: 14px !important;
    }
}

@media (max-width: 575.98px) {
    .w-25 {
        width: 48% !important;
    }

    .arabic .w-25:first-child,
    .english .w-25:last-child {
        margin-left: auto;
    }

    .arabic .w-25:last-child,
    .english .w-25:first-child {
        margin-right: auto;
    }
}</style>
