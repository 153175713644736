import mutations from './mutations'
import getters from './getters'
import actions from './actions'
export default {
  namespaced: true,
  state: {
      payment_method: '',
      shipping_address_id: '',
      billing_address_id: '',
      pickup : false
  },
  getters: getters,
  mutations: mutations,
  actions: actions
}
