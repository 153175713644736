export default class Address {
    constructor(address) {
        this.id = address.id,
        this.address_name = address.address_name,
        this.first_name = address.first_name,
        this.last_name = address.last_name,
        this.email = address.email,
        this.phone_number = address.phone_number,
        this.country = address.country,
        this.state = address.state ,
        this.region = address.region
        this.details = address.details
    }
}