<template>
    <swiper :cssMode="true" :breakpoints="{
        '200': {
            slidesPerView: 'auto',
            spaceBetween: 40,
        },
        '768': {
            slidesPerView: 3,
            spaceBetween: 40,
        },
        '1024': {
            slidesPerView: 3,
        },
        '1300': {
            slidesPerView: this.slidesPerView,
        },
    }" :autoplay="{
    delay: 2500,
    disableOnInteraction: false,
}" :pagination="!mobile" :modules="modules" class="header-swiper  h-88vh py-4 py-xl-0"
        :class="('slide-' + this.slidesPerView)">
        <swiper-slide v-for="(img, index) in this.header.images" :key="index">
            <router-link :to="img.direction_url" v-if="img.direction_url">
                <div class="img-container" v-bind:style="{ 'background-image': 'url(' + img.image_url + ')' }">
                </div>
            </router-link>
            <div v-else class="img-container" v-bind:style="{ 'background-image': 'url(' + img.image_url + ')' }">
            </div>
        </swiper-slide>
    </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

import { Autoplay, Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';

export default {
    inject: ['header'],
    props: ['slidesPerView'],
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Autoplay, Pagination],
        };
    },
    computed: {
        mobile() {
            return this.$store.getters.getMobile
        }
    }
};
</script>
  
<style scoped>
.h-88vh {
    height: 88vh;
}

.img-container {
    height: 68.4vh;
    width: 19.4vw !important;
    background-size: cover;
    background-position: center;
    border-radius: 23px;
}

.slide-2 .swiper-slide .img-container {

    margin-bottom: auto;
    margin-top: auto;
    /* margin-left: 15px;
    margin-right: 15px; */

}

.swiper-slide {
    display: flex;
}

.slide-4 .swiper-slide:nth-child(even) .img-container {
    margin-top: auto;
    margin-bottom: 5vh;
}

.slide-4 .swiper-slide:nth-child(odd) .img-container {
    margin-bottom: auto;
    margin-top: 5vh;
}

/* .swiper-slide:first-child{
    margin-right: 15px;
} */


/* responsive */
@media (max-width: 575.98px) {}

@media (max-width: 767.98px) {}


@media (max-width: 991.98px) {}


@media (max-width: 1199.98px) {
    .h-88vh {
        max-height: 74vh;
        height: fit-content;
    }

    .slide-4 .swiper-slide .img-container {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .slide-4 .swiper-slide {
        margin-top: .5vh !important;
        margin-bottom: .5vh !important;
    }


    .img-container,
    .swiper-slide {
        height: 440px;
        width: 266px !important;
    }
}
</style>
