import Address from "./Address";
import { CartItem } from "./CartItem";

export default class Order {
    constructor(order) {
        this.id = order.id,
        this.store_name = order.store_name,
        this.status = order.status,
        this.payment_method = order.payment_method,
        this.notes = order.notes,
        this.coupon = order.coupon,
        this.delivery_fee = order.delivery_fee
        this.formatted_total = order.total ,
        this.formatted_sub_total = order.subtotal ,
        this.formatted_fees_total = order.fees_amount ,
        this.formatted_tax_total = order.tax_amount ,
        this.formatted_discount = order.coupon_discount ,
        this.billing_address = order.billing_address ? new Address(order.billing_address): null,
        this.shipping_address = order.shipping_address ?new Address(order.shipping_address):null,
        this.pickup = (order.shipping_address== null)
        if(Array.isArray(order.items)) {
            this.items = order.items.map((item) => new CartItem(item));
        }
    }


}
