import BaseRepository from '@/repositories/Repository'

export default class CMSRepository extends BaseRepository {
  async getSettings () {
    return this.http.get('store-settings')
  }
  async getCMSBlocks () {
    return this.http.get('cms-blocks')
  }
  async getCurrencies() {
    return this.http.get('currencies')
  }
  async getPrivacyPolicy() {
    return this.http.get('privacy-policy')
  }
  async getAboutUs() {
    return this.http.get('about-us')
  }
  async getFAQs() {
    return this.http.get('faqs')
  }
}
