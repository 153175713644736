import RepositoryFactory from '@/repositories/RepositoryFactory'
import Cart from '@/models/Cart'
import store from '@/store/index'
export default class CartService {
  constructor () {
    this.cart = RepositoryFactory.get('cart')
  }

  async get () {
    store.dispatch('setLoader',true)
    return this.cart
      .get()
      .then(response => {
        let cart = new Cart(response.data.data)
        
        return Promise.resolve(cart)
      })
      .catch(error => {
        return Promise.resolve(null)
      }).finally(()=>{
        store.dispatch('setLoader',false)
      })
  }

  async add (product_id, qty) {
    store.dispatch('setLoader',true)
    return this.cart
      .add(product_id,qty)
      .then(response => {
        let cart = response.data.data
        return Promise.resolve(cart)
      })
      .catch(error => {
        Promise.resolve(null)
      }).finally(()=>{
        store.dispatch('setLoader',false)
      })
  }

  async updateQty (product_id, qty = 1) {
    store.dispatch('setLoader',true)
    return this.cart
      .updateQty(product_id, {quantity:qty})
      .then(response => {
        //waiting to done from backend
        let cart =new Cart(response.data.data)
        return Promise.resolve(cart)
      })
      .catch(error => {
        Promise.resolve(null)
      }).finally(()=>{
        store.dispatch('setLoader',false)
      })
  }

  async remove (item_id) {
    store.dispatch('setLoader',true)
    return this.cart
      .removeItem(item_id)
      .then(response => {
        let cart =new Cart(response.data.data)
        return Promise.resolve(cart)
      })
      .catch(err => Promise.reject(err)).finally(()=>{
        store.dispatch('setLoader',false)
      })
  }

  async destroy () {
    store.dispatch('setLoader',true)
    return this.cart
      .destroy()
      .then(response => {
        return Promise.resolve({
          cart: response.data.data ? new Cart(response.data.data) : null
        })
      })
      .catch(err => Promise.reject(err)).finally(()=>{
        store.dispatch('setLoader',false)
      })
  }

  async applyCoupon (code) {
    store.dispatch('setLoader',true)
    return this.cart
      .applyCupon(code)
      .then(response => {
        return Promise.resolve(new Cart(response.data.data) )
      })
      .catch(error => {
        return Promise.resolve(error.response.data.message)
      }).finally(()=>{
        store.dispatch('setLoader',false)
      })
  }

  async clearCupon () {
    store.dispatch('setLoader',true)
    return this.cart
      .clearCupon()
      .then(response => {
        return Promise.resolve(
        response.data.data ? new Cart(response.data.data) : null
        )
      })
      .catch(error => {
        Promise.resolve(error.response.data.message)
      }).finally(()=>{
        store.dispatch('setLoader',false)
      })
  }
}
