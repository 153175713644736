export default class Categories {
    constructor(categories) {
        this.name = categories.name,
        this.selected_option = categories.selectedOption,
        this.enable = categories.enable,
        // this.categories = categories.categories
        this.enable_searchbar = categories.enableSearchBar,
        this.title = categories.title ,
        this.paragraph = categories.paragraph,
        this.sort = categories.sort 
    }
}