<template>
    <div class="container-fluid" id="product-preview">
        <div class="row" v-if="succsess">
            <div class="col-xl-11 col-12 mx-auto px-0 my-lg-5 my-xl-3 mt-1 mb-3">
                <div class="row mx-0 w-100">
                    <div class="col-5 mx-auto px-0" v-if="!mobile">

                        <ProductImages :images="{ base_image: product.base_image, list: product.images }"
                            :productName="product.name" />
                    </div>
                    <div class=" col-11 mx-auto px-0" v-else>
                            <MobileImageSlider :images="product.images" :productName="product.name"/>
                    </div>
                    <div class="mx-auto p-start-5"  :class="!mobile? 'col-7':'col-11'">
                        <ProductDetails :product="sendProductWithoutImage()" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProductImages from '@/components/features/product/ProductImages.vue'
import MobileImageSlider from '@/components/features/product/MobileImageSlider.vue'
import ProductDetails from '@/components/features/product/ProductDetails.vue'
import BlindLoader from '@/components/UI/BlindLoader.vue'
import ServiceFactory from "@/services/ServiceFactory";
import metaMixin from '@/mixins/metaMixin';
import navMixin from '@/mixins/navMixin';
const products = ServiceFactory.get("products");
export default {
    mixins: [metaMixin, navMixin],
    async mounted() {
        window.scrollTo(0, 0);
        this.product = await products.get(`${this.$route.params.product_id}`);
        if (this.product) {
            var here = this;
            this.succsess = true;
            var store = this.$store.getters.getStoreName;
            this.updateTitle(store, this.product.meta_name ?? this.product.name);
            this.updateKeywords(store, this.product.meta_keywords ?? this.product.name)
            this.updateDescription(store, this.product.meta_description ?? this.product.name + ' ' + this.product.text_description)
            this.$router.replace({
                name: 'product', params: {
                    slug: here.product.url_key ?? (here.product.name.replaceAll(' ', '-')),
                    product_id: here.product.id
                },
            })
        }
    },
    components: {
        ProductImages,
        ProductDetails,
        BlindLoader,
        MobileImageSlider
    },
    data() {
        return {
            product: {},
            succsess: false,
        }
    },
    methods: {
        sendProductWithoutImage() {
            const { images: _, ...clone } = this.product
            return clone;
        },

    },
    computed: {
        mobile() {
            return this.$store.getters.getMobile;
        },
    }
}
</script>
<style scoped>
#product-preview {
    background-color: #ffffff;
}

.arabic .p-start-5 {
    padding-right: 3rem;
}

.english .p-start-5 {
    padding-left: 3rem;
}

@media (max-width: 575.98px) {}

@media (max-width: 767.98px) {
    .f-14 {
        font-size: 12px;
    }

    .f-24 {
        font-size: 20px;
    }

    .p-start-5 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }
}
</style>