export default {
    //normal checkout
    Checkout (state) {
        state.payment_method = ''; 
        state.shipping_address_id =  '',
        state.billing_address_id =  '',
        state.pickup = false 
      },
      PaymentMethod (state, val) {
        state.payment_method = val
      },
      ShippingId (state, val) {
        state.shipping_address_id = val
      },
      BillingId (state, val) {
        state.billing_address_id = val
      },
      Pickup(state, val) {
        state.pickup = val
      }
}