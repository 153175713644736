<template>
  <div class="row position-relative justify-content-center align-items-center">
    <div class="col-12 px-0">
      <swiper :pagination="{
        clickable: true,
      }" :autoplay="{
  delay: 2500,
  disableOnInteraction: true,
}" :modules="modules" class="mySwiper  h-88vh">
        <swiper-slide v-for="(img, index) in this.header.images" :key="index"> 
          <router-link :to="img.direction_url" v-if="img.direction_url">
            <div class="img-container h-100 w-100" v-bind:style="{ 'background-image': 'url(' + img.image_url + ')' }">
            </div>
          </router-link>
          <div v-else class="img-container  w-100 h-100" v-bind:style="{ 'background-image': 'url(' + img.image_url + ')' }" ></div>
         
        </swiper-slide>
      </swiper>
      <SearchBar position="absolute" class="w-500px mx-auto" />
    </div>
  </div>
</template>
<script>
import SearchBar from '@/components/UI/SearchBar.vue'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
export default {
  inject: ['header'],
  props: {
    enableSearch: {
      default: false,
      type: Boolean,
      validator: function (value) {
        return value == false || value == true;
      }
      // required: true
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    SearchBar
  },
  methods: {
    searchAbout() {
      this.emits('search', this.searchText)
    }
  },
  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
};

</script>
<style scoped>
.h-88vh {
  height: 88vh;
}

.w-500px {
  width: 500px
}

.img-container {
  background-image: url('../../../assets/img/default/placeholder.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

</style>
