import Address from './Address'
export default class CheckoutAdresses {
  constructor (data) {
    if (Array.isArray(data.addresses))
      this.addresses = data.addresses
    this.delivery_option = data.delivery_option,
    this.pickup_option = data.pickup_option
    
  }
}
