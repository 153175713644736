<template>
    <section class="categories container-fluid py-4" v-if="categories.list.length > 0">
        <div class="row my-4">
            <div class="col-11 px-lg-0  mx-auto">
                <SectionTitle :title="$t('categories')" routeName="categories" :routeText="$t('viewAllCategories')"/>
            </div>
        </div>
        <div class="row">
            <div class="col-11 px-lg-0  mx-auto" v-if="categories.selected_option == 'Categories_A'">
                <div class="d-flex justify-content-between overflow-x-scroll">
                    <CircleCard v-for="category in getShortList(7)" :key="category.id" :category="category" />
                </div>
            </div>
            <div class="col-11 px-lg-0   mx-auto " v-else>
                <div class="row  overflow-x-scroll">
                    <div class="col-xl-2 col-md-4 col-6" v-if="!mobile">
                        <TextSquerCard />
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 my-4" v-for="category in getShortList(mobile ? 6 : 5)" :key="category.id">
                        <SquerCard :category="category" />
                    </div>
                </div>
            </div>
            
        </div>
    </section>
</template>
<script>
import TextSquerCard from '../../blocks/categories/TextSquerCard.vue'
import CircleCard from '../../blocks/categories/CirclreCard.vue'
import SquerCard from '../../blocks/categories/SquerCard.vue'
import SectionTitle from '../../UI/SectionTitle.vue'
export default {
    props: {
        categories: {
            type: Object,
            required:true ,
            validator:function(value) {  
                return 'list' in value
                //values should be change depends on options keies in backend
            }
        }
    },
    methods: {
        getShortList(shortListSize) {
            return this.categories.list.slice(0, shortListSize);
        },
    },
    components: {
        SectionTitle,
        SquerCard,
        CircleCard,
        TextSquerCard
    },
    computed:{
        mobile() {
            return  this.$store.getters.getMobile;
    },
    }
}
</script>
<style scoped>
.overflow-x-scroll {
    overflow-x: auto!important;
}
.categories{
    background-color: #F9F9F9;
}
</style>