<template>
    <section class="product-slider-section container-fluid py-4 position-relative">
        <div class="row my-3 ">
            <div class="transparent-bg primary-bg position-absolute t-0 b-0 l-0 r-0 w-100 h-100"></div>
            <div class="col-lg-11 px-lg-0 col-12  mx-auto position-relative z-index-1">
                <SectionTitle :title="title!='' ?title: products.name" />
            </div>
            <div class="col-lg-11 px-lg-0 col-12  mx-auto position-relative z-index-1 d-flex overflow-x mt-4 pt-3">
                <!--   :navigation="true" -->
                <swiper :breakpoints="{
                    '200': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '375': {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    '450': {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    '768': {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    '1024': {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    '1300': {
                        slidesPerView: 6,
                        spaceBetween: 20,

                    },
                }" :autoplay="{
    delay: 4000,
    disableOnInteraction: true,
}" :pagination="{
    clickable: true,
}" :modules="modules" class="mySwiper product-swiper pb-5 w-100">
                    <swiper-slide v-for="product in products.products" :key="product.id">
                        <ProductCard :product="product"/>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </section>
</template>
<script>
import SectionTitle from '@/components/UI/SectionTitle.vue';
import ProductCard from '@/components/UI/ProductCard.vue'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

import { Navigation, Autoplay, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';

export default {
    props: {
        products: {
            type: Object,
            required: true
        },
        title:{
            type: String ,
            default:''
        }
    },
    components: {
        SectionTitle,
        ProductCard,
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Autoplay, Pagination, Navigation],
        };
    }
}
</script>
<style scoped>
.overflow-x {
    overflow-x: auto;
}

.transparent-bg {
    opacity: 5%;
    z-index: 0;
}

.z-index-1 {
    z-index: 1;
}
</style>

