import cookiesHelper from "@/helpers/CookiesHelper";

const TOKEN_KEY = 'token';

export const hasToken = () => {
    return !!cookiesHelper.get(TOKEN_KEY);
};

export const isLoggedIn = () => {
    return hasToken();
};

export const refreshToken = async () => {

};

export const getToken = () => {
    return cookiesHelper.get(TOKEN_KEY);
};

export const setToken = (token) => {
    cookiesHelper.set(TOKEN_KEY, token);
};

export const logout = () => {
    cookiesHelper.remove(TOKEN_KEY);
};

export default {
    hasToken,
    refreshToken,
    isLoggedIn,
    getToken,
    setToken,
    logout
};