import BaseRepository from '@/repositories/Repository'

export default class CartRepository extends BaseRepository {
  async get () {
    return this.http.get('cart' ,{
        withCredentials: true,
      })
  }

  async add (productId, qty) {
    return this.http.post(`addToCart/${productId}`, qty)
  }

  async destroy () {
    return this.http.get('empty-cart')
  }

  async updateQty (itemId, newQty) {
    return this.http.post(`update-qty/${itemId}`, newQty)
  }

  async removeItem (itemId) {
    return this.http.get(`removeFromCart/${itemId}`)
  }

  async applyCupon(code){
    return this.http.post('apply-coupon',code)
  }

  async clearCupon(code){
    return this.http.post('unset-coupon',code)
  }
}
