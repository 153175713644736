import RepositoryFactory from '@/repositories/RepositoryFactory'
import Category from '@/models/Category'
import store from '@/store/index'

export default class CategoryService {
  constructor () {
    this.category = RepositoryFactory.get('categories')
  }
  async getCategory (id,params='') {
    store.dispatch('setLoader', true)
    return this.category
      .get(id+`?${params}`)
      .then(response => {
        let response_object = {}
        let category = new Category(response.data.data)
        response_object.category = category
        response_object.products = response.data.data.products
        response_object.pagination = response.data.data.pagination
        return Promise.resolve(response_object)
      })
      .catch(error => {
        Promise.resolve(null)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }

  async list () {
    store.dispatch('setLoader', true)
    return this.category
      .list()
      .then(response => {
        let categories = []
        let res = {}
        response.data.data.categories.forEach(function (category) {
          let cat = new Category({ category })
          categories.push(cat)
        })
        res.categories = categories
        if (response.data.data.featured_products)
          res.featured_products = response.data.data.featured_products
        res.count = response.data.data.count
        return Promise.resolve(res)
      })
      .catch(error => {
        Promise.resolve(null)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }
}
