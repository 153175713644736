export default class Category {
  constructor (data) {
    this.id = data.category.id
    this.name = data.category.name
    this.product_count = data.products_count ?? '0'
    this.slug = data.category.seo_meta.slug
    this.meta_title = data.category.seo_meta.name
    this.meta_description = data.category.seo_meta.description
    this.meta_keywords = data.category.seo_meta.keywords
    this.children = data.sub_categories ?? []
    this.cover = data.category.cover;
    this.image = data.category.image;
    this.breadcrumb = data.parent_tree ?? []
    this.max_price = data.price_range ? data.price_range.max : 0
    this.min_price = data.price_range ? data.price_range.min:0 
    this.less_than = data.applied_filters&&data.applied_filters.price_less_than&&data.applied_filters.price_less_than!=0 ? data.applied_filters.price_less_than:this.max_price
    this.more_than = data.applied_filters&&data.applied_filters.price_more_than&&data.applied_filters.price_more_than!=0 ? data.applied_filters.price_more_than:this.min_price
    this.sort_type = data.applied_filters ? data.applied_filters.sort_type : null
  }
}
