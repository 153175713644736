<template>
    <router-link :to="{
        name: 'category',
        params: {
            slug: category.slug,
            category_id:category.id
        }
    }" class="h-fit-content w-100">
        <div class="w-100 category-card position-relative text-center d-flex justify-content-center">
            <div class="d-flex align-items-center justify-content-center w-100">
                <div class="rounded-squer" v-bind:style="{ 'background-image': 'url(' +category.image + ')' }">
                </div>
            </div>
            <div class="overlay w-100 h-100 position-absolute  l-0 r-0"></div>
            <p class="category-name ellipsis w-fit-content d-flex m-auto f-18 semibold position-absolute t-0 b-0 l-0 r-0 text-capitalize">
                {{ category.name }}</p>
        </div>
    </router-link>
</template>
<script>
export default {
    props: {
        category: {
            type: Object,
            required: true
        }
    }
}
</script>
<style scoped>
.h-fit-content{
    height: fit-content;
    display: inline-block;
}
.category-card {
    width: fit-content;
    overflow: hidden;
}

.rounded-squer {
    padding-top: 100%;
    width: 100%;
    border-radius: 22px;
    background-size: cover;
    background-position: center;
}

.overlay {
    background-color: #29292900;
    border-radius: 22px;
    transition: all .3s ease-in-out;
    width: fit-content;
    height: fit-content;
    /* padding-top: 100%;
    width: 185px!important;
    margin: auto; */
}

.category-name {
    color: white !important;
    top: 110%;
    transition: all .2s ease-in-out;
    width: fit-content;
    height: fit-content;

}

.category-card:hover .overlay {
    background-color: #29292985;
    transition: all .2s ease-in-out;
}

.category-card:hover .category-name {
    top: 0;
    bottom: 0;
    transition: all .3s ease-in-out;
}

@media (max-width: 1199.98px) {
    .mobile-w-100{
        width: 100%;
    }
    .rounded-squer {
        width: 100%;
        height: 0;
        padding-top: 100%;
    }
}
@media (min-width: 1200px) and (max-width: 1800.98px) {
    .categories-list .rounded-squer {
        width: 200px;
        padding-top:200px ;
    }
    .categories-list .overlay {
    padding-top: 200px;
    width: 200px!important;
    margin: auto;
}
}
</style>