import Cart from '@/models/Cart'
import CheckoutAdresses from '@/models/CheckoutAdresses'
import PaymentMethod from '@/models/PaymentMethod'
import RepositoryFactory from '@/repositories/RepositoryFactory'
import store from '@/store'

export default class CheckoutService {
  constructor () {
    this.checkout = RepositoryFactory.get('checkout')
  }

  async getCheckoutAddresses() {
    store.dispatch('setLoader', true)
    return this.checkout
      .addresses()
      .then(response => {
        var checkout = new CheckoutAdresses(response.data.data);
        return Promise.resolve(checkout)
      })
      .catch(error => {
        return Promise.resolve(null)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }
  async getPaymentMethods () {
    store.dispatch('setLoader', true)
    return this.checkout
      .paymentMethods()
      .then(response => {
       var methods = response.data.data.map((method) => new PaymentMethod(method));
       return Promise.resolve(methods)
      })
      .catch(error => {
        Promise.resolve(null)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }
  async saveOrder (order) {
    store.dispatch('setLoader', true)
    return this.checkout
      .saveOrder(order)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(err => {
        return Promise.resolve(err.response.data)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }
  async getCheckoutSummary(){
    store.dispatch('setLoader', true)
    return this.checkout
      .getSummary()
      .then(response => {
        let summary =new Cart(response.data.data)
        return Promise.resolve(summary)
      })
      .catch(err => {
         Promise.resolve(null)
      })
      .finally(() => {
        store.dispatch('setLoader', false)
      })
  }

}
