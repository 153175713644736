<template>
    <router-link :to="{
        name: 'category',
        params: {
            slug: category.slug,
            category_id: category.id
        }
    }">
        <div class="category-card position-relative text-center d-flex justify-content-center my-4">
            <div class="circle-border primary-border d-flex align-items-center justify-content-center mb-3">
                <div class="circle primary-border"
                    v-bind:style="{ 'background-image': 'url(' + category.image + ')' }">
                </div>
            </div>
            <p class="category-name ellipsis w-fit-content d-flex m-auto f-16 semibold position-absolute t-0 b-0 l-0 r-0 text-capitalize">{{
                category.name }}</p>
        </div>
    </router-link>
</template>
<script>
export default {
    props: {
        category: {
            type: Object,
            required: true
        }
    },

}
</script>
<style scoped>
.category-name {
    z-index: 0;
    opacity: 0%;
    transition: .3s all ease-in-out;
    cursor: pointer;
}

.w-fit-content {
    width: fit-content;
    height: fit-content;
}

.category-card {
    width: fit-content;
}

.circle {
    height: 130px;
    width: 130px;
    border-radius: 100%;
    background-size: cover;
    /* border: 1px solid; */

    background-position: center;
    transition: .2s all ease-in-out;
}

.circle-border {
    z-index: 2;
    position: relative;
    border: 1.5px solid;
    height: 145px;
    width: 145px;
    border-radius: 100%;
    background-color: #F9F9F9;
}

.category-card:hover .circle {
    height: 120px;
    width: 120px;
    transition: .2s all ease-in-out;
}

.category-card:hover .category-name {
    z-index: 1;
    opacity: 100%;
    top: 100%;
    transition: .3s all ease-in-out;

}
</style>