export default class Product {
  constructor (data) {
    this.id = data.product.id
    this.type = data.product.type
    this.url_key = data.product.seo_meta.slug
    this.name = data.product.name
    this.price = data.product.price
    this.formatted_price = data.product.formatted_price
    this.html_description = data.product.description
    this.text_description = data.product.description_without_style
    this.require_end_date = data.product.require_end_date
    this.require_hour = data.product.require_hour
    this.images = data.product.album
    this.base_image = data.product.base_image
    this.breadcrumb = data.parent_tree
    this.meta_name = data.product.seo_meta.name
    this.meta_keywords = data.product.seo_meta.keywords
    this.meta_description = data.product.seo_meta.description
  }

  isRequireEndDate () {
    return this.require_end_date
  }

  isRequireHour () {
    return this.require_hour
  }

  isService(){
    return this.type == 'service'
  }
}
