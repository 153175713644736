import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
export default {
  namespaced: true,
  state: {
    personalInfo: {
      customer_first_name: '',
      customer_last_name: '',
      customer_phone_number: ''
    },
    delivery:false,
    pickup : false,
    payment_method: '',
    billing_address: {
      address_name: '',
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      country: '',
      city: '',
      region: '',
      details: ''
    },
    shipping_address: {
      address_name: '',
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      country: '',
      city: '',
      region: '',
      details: ''
    },
  },
  getters: getters,
  mutations: mutations,
  actions: actions
}
