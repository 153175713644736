
const navMixin = {
  data () {
    return {
      prev: 0
    }
  },
  mounted () {
    if(!this.$store.getters.getMobile)
      window.onscroll = this.makeScroll
  },
  beforeDestroy () {
    var here = this
    window.removeEventListener('scroll', here.makeScroll)
  },
  methods: {
    makeScroll () {
      var nav = document.getElementById('navigation-bar')
      var body = document.getElementById('body-wrapper')
      if (window.scrollY < this.prev) {
        body.classList.add('py-12vh')
        nav.classList.add('opacity-animate')
        nav.style.position = 'fixed'
      } else {
        body.classList.remove('py-12vh')
        nav.style.position = 'inherit'
        nav.classList.remove('opacity-animate')
      }
      this.prev = window.scrollY
    }
  }
}
export default navMixin
